@import '../../../../styles/main.scss';

.scatter-chart-container {
  margin-bottom: $xxl !important;
  position: relative;
  .chart-top-section {
    display: flex;
    justify-content: flex-end;

    .chart-legends {
      display: flex;
      font-weight: $font-bold;

      > div {
        margin-left: $xs;
        padding: $s;
        display: flex;
        text-align: center;
        p {
          margin: 0 0 0 $xs;
        }
      }

      .chart-legend-color {
        height: $font-size-xxs;
        width: $font-size-xxs;
        margin-right: $xs;
      }
    }
  }
  @include media-xl() {
    position: inherit;
  }
}

.chart-legend-color-tooltip {
  width: $s;
  height: $s;
  margin-right: $s;
  position: absolute;
  top: $font-size-xxs;
  padding-right: $s;
  background-color: rgba(27, 122, 194, 1);
}

.chart-legend-content {
  padding-left: $m;
  text-align: initial;
  top: $font-size-s;
  padding-right: $s;
}

.chart-legends {
  display: flex;
  height: $xxl;
  left: calc(50% - 162px / 2 + 4px);
  top: calc(50% - 48px / 2 - 5.5px);
  font-family: var(--font-family-abb);
  font-style: normal;
  font-weight: $font-regular;
  font-size: $sm;
  line-height: $line-height-s;
  align-items: center;
  color: $color-grey-90;

  .circle {
    width: $s;
    height: $s;
    background: rgba(237, 87, 57, 1);
    transform: translate(-50%, -50%);
    transform: rotate(-45deg);
    transform-origin: 0 100%;
    border-radius: 50%;
    margin-top: $xs;
  }
}

.content1 {
  font-weight: $font-light;
}

.content2,
.content3 {
  font-weight: $font-regular;
}

.chart-legends-tool {
  height: auto;
  left: calc(50% - 162px / 2 + 4px);
  top: calc(50% - 48px / 2 - 5.5px);

  /* Regular / Small body text */
  font-style: normal;
  font-weight: $font-light;
  font-size: $sm;
  line-height: $m;
  /* or 133% */
  align-items: center;

  /* Font / Light / Primary */
  color: $color-grey-90;
}

.diamond-narrow {
  width: $s;
  height: $s;
  background: rgba(181, 170, 13, 1);
  transform: translate(-50%, -50%);
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.diamond-tool-content {
  padding-left: $font-size-ml;
  text-align: initial;
  top: $font-size-s;
  padding-right: $s;
  padding-bottom: $s;
}

.diamond-narrow-tooltip {
  width: $s;
  height: $s;
  background: rgba(181, 170, 13, 1);
  transform: translate(-50%, -50%);
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  margin-left: $s;
  position: absolute;
  top: $font-size-xxs;
}

.circle-tool-content {
  padding-left: $font-size-ml;
  text-align: initial;
  top: $font-size-s;
  padding-right: $s;
}

.circle-toolTip {
  width: $s;
  height: $s;
  background: rgba(237, 87, 57, 1);
  border-radius: 50%;
  margin-right: $s;
  position: absolute;
  top: $font-size-xxs;
}
