@import '../../../../styles/main.scss';
.drive-configurator-dual-layout {
  @include flex-align-center-vertical();
  align-items: flex-start;
  gap: $s;
  align-self: stretch;
  .drive-config-input {
    border-radius: $s;
    padding: $ml;
    background: $color-content-background-light-snow;
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $ml;
    flex: 1 0;

    .drive-config-input-card {
      background-color: $color-background-black-tertiary;

      color: $color-brand-white;
      border-radius: $s;
      width: 100%;
      .card-container {
        background-color: inherit !important;
        width: 100%;
      }
    }
    .drive-config-input-card-reconfigure {
      background-color: $background-alternative;
      color: $color-text-black-primary;
      border-radius: $s;
      width: 100%;
      .card-container {
        background-color: inherit !important;
        width: 100%;
      }
    }
    .drive-config-card-content {
      @include flex-align-center-vertical();
      gap: $m;
      align-items: center;
      justify-content: space-between;
      div {
        @include flex-align-center-vertical();
        flex-direction: column;
        align-items: start;
        gap: $xs;
      }
      h5 {
        font-size: $font-size-ml;
        font-weight: $font-medium;
        line-height: $line-height-xl;
      }
      p {
        margin: 0;
        font-size: $font-size-s;
        font-weight: $font-regular;
        line-height: $line-height-m;
      }
      .disable-card-text {
        h5 {
          color: $color-foreground-disable;
          text-align: center;
          font-size: $font-size-ml;
          font-weight: $font-medium;
          line-height: $line-height-xl;
        }
        p {
          color: $color-foreground-disable;
          margin: 0;
          font-size: $font-size-s;
          font-weight: $font-regular;
          line-height: $line-height-m;
        }
      }
    }
  }

  .drive-config-results {
    padding: $ml;
    border-radius: $s;
    background-color: $color-content-background-light-snow;
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $ml;
    flex: 1 0;
    .selection-header {
      @include flex-align-center-vertical();
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }

  .selection-header {
    @include flex-align-center-vertical();
    gap: $s;
    padding-right: $s;

    h5 {
      font-size: $font-size-ml;
      font-weight: $font-medium;
      line-height: $line-height-xl;
    }
  }
  .drive-config-input-footer {
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $m;
    align-self: stretch;
    h6 {
      color: $color-brand-black;
      font-size: $font-size-m;
      font-weight: $font-medium;
      line-height: $line-height-xl; /* 150% */
    }
    p {
      color: $color-brand-black;
      font-size: $sm-14;
      font-weight: $font-regular;
      line-height: $line-height-s; /* 114.286% */
      text-transform: uppercase;
      margin: 0;
    }
    h4 {
      color: $color-text-black-primary;
      font-size: $font-size-ml;
      font-weight: $font-medium;
      line-height: $line-height-xl; /* 150% */
    }
    .drive-config-image-container {
      @include flex-align-center-vertical();
      align-items: flex-start;
      gap: $m;
      align-self: stretch;
      margin-top: 0;
      p {
        text-transform: unset;
        margin: 0;
        font-size: $sm-14;
        font-weight: $font-regular;
        line-height: $line-height-m;
      }
      .desc-container {
        align-self: stretch;
        margin-left: 0;
        width: 50%;
        p {
          color: $color-brand-black;
          font-size: $sm-14;
          font-weight: $font-regular;
          line-height: $line-height-m; /* 142.857% */
        }
      }

      .img-container {
        max-height: 154px;
        max-width: 154px;
        width: 100%;
      }
    }
  }
}

commonux-datagrid-header {
  .label-container {
    color: $color-text-black-secondary !important;
    font-size: $sm-14;
    font-weight: $font-medium;
    line-height: $line-height-m; /* 142.857% */
    text-transform: uppercase;
  }
}

.drive-result-table {
  @include flex-align-center-vertical();
  flex-direction: column;
  align-items: flex-start;
  gap: $s;
  align-self: stretch;
  width: 100%;
  p {
    color: $color-text-black-secondary;
    font-size: $sm-14;
    font-weight: $font-regular;
    line-height: $line-height-s; /* 114.286% */
    text-transform: uppercase;
    margin: 0;
    padding: $s 0;
  }
}

.lvdrivetypecode-modal {
  .backdrop .dialog {
    width: 100%;
  }

  .longtypecode-container {
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $s;
    align-self: stretch;
    .longtypecode-content {
      @extend .longtypecode-container;
      p {
        margin: 0;
        color: $color-text-black-primary;
        font-size: $sm-14;
        font-weight: $font-regular;
        line-height: $line-height-s; /* 114.286% */
      }
    }

    ion-radio-group {
      @include flex-align-center-vertical();
      flex-direction: column;
      gap: $s;
    }
    commonux-radio {
      padding: 6px 0;
    }
    .longtypecode-footer {
      @include flex-align-center-vertical();
      justify-content: flex-end;
      align-items: center;
      gap: $m;
      align-self: stretch;
    }
  }
  .footer {
    display: none !important;
  }
}

.loading-indicator-container {
  @include flex-align-center-vertical();
  align-items: center;
  gap: $s;
  .loader-container {
    width: unset;
  }
  p {
    margin: 0;
    width: 100%;
    color: $color-brand-black;
    font-size: $sm-14;
    font-weight: $font-bold-700;
    line-height: $line-height-m; /* 142.857% */
  }
}

.drive-result-table-header {
  @include flex-align-center-vertical();
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.delete-icon-container {
  padding-right: $s;
}

.drive-config-card-icon-and-text {
  display: flex;
  gap: $ml;
  flex-direction: row !important;
}
.data-grid {
  .icon-menu-container {
    button {
      display: none;
    }
  }
  &:hover {
    .icon-menu-container {
      button {
        display: block;
      }
    }
  }
}
