@import "variables";
@import "../../src/styles/main.scss";

.button-group-mobile {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: $m;
  left: 11%;
  width: 77%;
  height: $xl;
  border-radius: 100px;
  background-color: $color-brand-black;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px $s rgba(0, 0, 0, 0.04),
    0px $m $ml rgba(0, 0, 0, 0.12);

  .right-button {
    border-bottom-left-radius: 28px;
    border-top-left-radius: 28px;
    height: $xl;
    border: none;
    padding-left: $ml;
  }

  .button-group-common {
    color: $color-grey-20;
    line-height: $line-height-s;
    font-weight: $font-medium;
    font-size: $font-size-s;
    background-color: #000000e0;
    display: flex;
    flex-direction: row;
    padding-top: $sm;
  }

  .divider {
    border-left: 1px solid $color-brand-black;
    margin-top: $xs;
    margin-bottom: $xs;
  }

  .disabled {
    color: $color-text-white-disabled;
  }

  .left-button-name-icon-container {
    display: flex;
  }

  .select-option-container {
    position: relative;
    display: inline-block;
    width: 50%;
    background-color: $color-black-primary;
    border-bottom-left-radius: 28px;
    border-top-left-radius: 28px;

    .dropdown-toggle {
      background-color: $color-black-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $color-text-white-primary;
      border: none;
      border-bottom-left-radius: 28px;
      border-top-left-radius: 28px;
      padding-left: $ml;
      padding-right: $m;
      height: $xl;
      font-size: $font-size-s;
      line-height: $line-height-s;
      font-weight: $font-medium;

      .button-name {
        padding-right: $xs;
      }

      &:focus {
        outline: none;
      }
    }

    .dropdown-menu {
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background-color: $color-white-primary;
      border-radius: $xs;
      margin-top: $xs;
      box-shadow: 0px 6px $sm rgba(0, 0, 0, 0.25);
      width: 142px;
    }

    .dropdown-option {
      padding-top: $s;
      padding-left: $m;
      padding-bottom: $s;
    }

    .selected-option {
      background-color: $color-grey-10;
    }
  }

  .result-button {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
    padding-right: $m;

    .arrow-icon-disabled {
      fill: $color-text-white-disabled;
    }
  }

  .back-button-name {
    padding-left: $s;
    padding-right: $xs;
  }

  .right-button-name {
    padding-left: $s;
    padding-right: $xs;
  }
}
