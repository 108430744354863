@import 'variables';
@import 'font';
@import 'utils';

body {
  background-color: $color-content-background-light-cloud;
  font-family: $font-family, sans-serif;
  font-size: $font-size-m;
  line-height: $line-height-s;
  box-sizing: border-box;
  color: $color-text-black-primary;
  margin: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}
a {
  text-decoration: none;
}

#root {
  overflow: auto;
  height: 100%;
}

body.sidebar-container {
  height: 100vh;
  overflow-y: hidden;
}

@mixin media-mobile {
  @media all and (max-width: $mobileMaxWidth) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: $tabletMinWidth) and (max-width: $tabletMaxWidth) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $desktopMinWidth) and (max-width: $desktopMaxWidth) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: $xlMinWidth) {
    @content;
  }
}

@mixin media-xxl {
  @media (min-width: $xxlMinWidth) {
    @content;
  }
}

//typography classes
h1 {
  font-size: $font-size-xxxl;
  line-height: 1 $sm;
}

h2 {
  font-size: $font-size-xxl;
  line-height: 72px;
}

h3 {
  font-size: $font-size-xl;
  line-height: 56px;
}

h4 {
  font-size: $font-size-l;
  line-height: 36px;
}

h5 {
  font-size: $font-size-ml;
  line-height: $line-height-xl;
}

h6 {
  font-size: $font-size-m;
  line-height: $line-height-xl;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2 {
  font-weight: $font-regular;
}

h3,
h4,
h5,
h6 {
  font-weight: $font-bold;
}

table.print-layout-table {
  width: 100%;
  word-wrap: break-word;
  table-layout: fixed;

  .hidden-inpreview {
    display: none;
  }

  .empty-footer {
    display: none;
  }
}

table {
  width: 100%;
}

.ReportPanel-Top {
  padding: var(--spacing-4);
  text-transform: uppercase;
  color: var(--color-grey-60);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-variant: small-caps;
}

.print-layout-table {
  .report-section {
    width: 100%;
  }

  .report-page-copyright-content {
    margin-top: 320px;
  }

  .report-project {
    font-size: 28px;
  }
}

.layout-container {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;

  @include media-mobile() {
    width: auto;
    padding: $xl $m 0;
  }

  @include media-tablet() {
    width: auto;
    padding: $xl $ml 0;
  }

  @include media-desktop() {
    width: 1140px;
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workspace-loader-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

commonux-native-header .wrapperdiv > .small {
  padding: $m;
}

.commonux-modal-popup {
  .backdrop {
    align-items: start !important;

    .dialog {
      margin-top: $font-size-ml;

      .modal-content {
        display: block;
        width: 504px;

        & > :not(:first-child) {
          margin-top: $m;
        }

        .input-style {
          width: 452px;
          margin-right: 26px;
        }

        .tab-content {
          margin-top: $s;
          padding-left: $s;
          display: flex;
          flex-direction: column;
          gap: $s;
        }

        .select-style {
          display: block;
          margin-right: 26px;
          width: 452px;

          .wrapper {
            max-width: 100%;
          }
        }

        .checkbox-style {
          display: flex;
          flex-direction: column;
          gap: $m;

          .content {
            display: none;
          }
        }

        .radiobtn-style {
          ion-radio-group {
            display: flex;
            flex-direction: row;
            gap: $ml;
          }

          .content {
            padding: 0;
          }
        }
      }
    }
  }
}

.selContainer {
  margin-bottom: 2rem;
}

.label-container {
  cursor: pointer;
}

.disp-flex {
  display: flex;
  justify-content: space-between;
}

.cursor {
  cursor: pointer;
}

commonux-tooltip .medium {
  padding: 0 !important;
}

commonux-tooltip .tooltip {
  padding: $sm !important;
}

commonux-tooltip .suffix-container {
  padding: $s $m !important;
}

commonux-tooltip .icon-information-circle {
  max-width: $xl !important;
}

@mixin custom-scrollbar($isHorizantalscrollrequired: false) {
  &::-webkit-scrollbar {
    width: $m;
  }
  &::-webkit-scrollbar-track {
    background: $color-black-opacity-2;
  }
  &::-webkit-scrollbar-corner {
    background-color: $color-brand-white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 32px !important;
    border: none;
  }
  &::-webkit-scrollbar-button:single-button {
    background-color: $color-brand-white;
    display: block;
    width: $m;
    height: $m;
  }
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url('../pages/Workspace/styles/triangledown.svg');
    background-repeat: no-repeat;
  }
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url('../pages/Workspace/styles/triangleup.svg');
    background-repeat: no-repeat;
  }
  @if $isHorizantalscrollrequired {
    &::-webkit-scrollbar-button:single-button:vertical:increment {
      background-image: url('../pages/Workspace/styles/triangledown.svg');
      background-repeat: no-repeat;
    }
    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      background-image: url('../pages/Workspace/styles/triangleup.svg');
      background-repeat: no-repeat;
    }
  }
}

@mixin customflex() {
}

.d3-tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  font: $font-size-xs sans-serif;

  pointer-events: none;
  opacity: 0;
  z-index: 1;
  padding: $xs;
  background: $color-brand-white;
  border: $border-width-s solid $color-grey-30;
}
