@import '../../styles/_variables.scss';
@import '../../styles/_utils.scss';
@import '../../styles/main.scss';

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  min-height: 100vh;
  width: 100%;

  @include responsive-media-query($media-lg, $media-xl) {
    width: 100%;
  }

  & > div > .header {
    position: sticky;
    top: 0;
    z-index: 23;

    .header-conatiner {
      background-color: var(--background-primary);
    }

    .lang-selection {
      display: flex;
      gap: $xs;
      font-weight: 600;
    }

    .globalControls-container {
      display: flex;
      gap: $m;
      margin-right: $s;

      @include media-mobile() {
        margin-right: $s;
      }

      .unauthorized {
        margin-right: 24px;
      }
      .user-profile {
        display: block;
        height: auto;

        .user-profile-authorized {
          button {
            padding: 4px !important;
          }
        }

        button {
          padding: $xs 12px;
          @include media-mobile() {
            padding: $xs;
          }
        }

        .login-button {
          .tertiary {
            .text {
              @include media-mobile() {
                display: none;
              }
            }
          }
        }
      }
      .open {
        width: max-content;
        float: right;
        position: absolute;
        margin-right: $ml;
      }
    }
    .globalControls-container-authorization {
      margin-right: $s;
      @include media-mobile() {
        margin-right: 0;
      }
    }
  }
  .globalControls-slot-container {
    @include media-mobile() {
      margin-right: 0;
    }
  }

  & > .option-card > .card-container {
    padding: $l;
  }

  .project-status-container {
    display: flex;
    padding-left: $sm;
    color: $color-text-black-secondary;
    font-size: $font-size-s;
    font-weight: 400;

    .primary-black {
      padding: 4px 12px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 92px;
    align-self: stretch;
    padding: $xxl 0 $ml;
    & > :nth-child(1) {
      @include media-mobile() {
        order: 1;
      }
    }

    @include media-mobile() {
      flex-direction: column;
      align-items: center;
      gap: $ml;
      margin: 0;
      padding-top: 0;
    }
    @include media-tablet() {
      flex-direction: row;
      align-items: center;
      gap: $sm;
      padding-top: 0;
    }
    @include media-desktop() {
      gap: 54px;
      padding-top: $ml;
    }
  }
  .copyright {
    font-size: $font-size-xs;
    color: $color-brand-black;
    font-style: normal;
    font-weight: $font-regular;
    line-height: $line-height-xs;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $l;
    flex: 1 0 0;
    cursor: pointer;
    section {
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: $m;

      @include media-mobile() {
        padding: 0;
      }
    }

    p {
      color: $color-text-black-secondary;
      margin: 0;
      padding: 0 $m;
      @include media-desktop() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-tablet() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-mobile() {
        padding: 0;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    @include media-mobile() {
      flex-direction: column;
      gap: $xs;
      align-items: center;
      order: 0;
      font-size: $font-size-s;
      line-height: normal;
      p {
        margin: 0;
      }
    }
    @include media-tablet() {
      flex-direction: row;
      gap: $xs;
      font-size: $font-size-s;
    }
    @include media-desktop() {
      gap: $sm;
    }
  }

  .footer-link {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $m;
    p {
      color: $color-text-black-secondary;
      text-align: center;
      margin: 0;
      font-size: $font-size-m;
      font-style: normal;
      font-weight: $font-regular;
      line-height: $line-height-xl;
      cursor: pointer;
      @include media-desktop() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-tablet() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-mobile() {
        font-size: $font-size-s;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    @include media-mobile() {
      padding: 0;
    }
  }

  .footer-section-container {
    margin-top: 84px;
    @include media-mobile() {
      margin-top: $l;
    }
    .footer-container {
      display: flex;
      flex-direction: column;
      padding: $ml;
      gap: $ml;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      @include media-mobile() {
        background-color: $color-brand-white;
      }
      .footer-header-container {
        width: 100%;
        border-top: $border-width-s solid $color-footer-border;

        @include media-mobile() {
          border: 0;
          background-color: $color-white-primary;
        }
      }
    }
  }
  @include media-tablet() {
    margin: 0;
  }
  @include media-mobile() {
    margin: 0;
  }
}

.brand-name {
  color: $color-text-black-secondary;
  font-size: $m;
}

.header-brandplate {
  cursor: auto;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .navigation-icon {
    fill: $color-text-black-secondary;
  }
}

.breadcrumb-last-item {
  font-size: $m;
  font-weight: $font-medium;
  line-height: $ml;
}

.primaryNav-slot-container {
  display: flex !important;
  margin: 0 !important;
}

*:not(.selection-modal .dialog .content):not(
    .manual-selection-modal .dialog .content
  ):not(.left-pane-container)::-webkit-scrollbar {
  display: none;
}

.dual-section-container {
  display: flex;
  gap: $s;
}

.left-side-container {
  background-color: $color-white-primary;
  border-radius: $s;
  padding: $ml;
  width: 60%;
  position: relative;

  @include media-xl() {
    width: 50%;
  }

  @include media-mobile() {
    flex-direction: column;
    width: 100%;
    padding: $m;
  }
}

.right-side-container {
  width: 40%;
  background-color: $color-white-primary;

  border-radius: $s;
  padding: $ml;
  height: -moz-fit-content;
  height: fit-content;

  position: relative;

  @include media-xl() {
    width: 50%;
  }

  @include media-mobile() {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: $m;
  }
}

.section-content-header-text {
  margin-top: $ml;
  margin-left: $m;
  font-size: $font-size-ml;
  font-weight: $font-medium;
}

.sidebar-collapsed-left-side-container {
  background-color: $color-white-primary;
  border-radius: $s;
  width: 50%;
  padding: $ml;
}

.sidebar-collapsed-right-side-container {
  margin-right: $m;
  width: 50%;
  background-color: $color-white-primary;
  margin-left: $s;
  border-radius: $s;
  padding: $m;
  height: -moz-fit-content;
  height: fit-content;
}
@include media-tablet() {
  commonux-footer .footer-container [slot='footerTop'] {
    display: flex;
    flex-direction: row;
    margin-top: $xl;
  }
}

.feedback-button {
  position: fixed;
  top: 65%;
  right: 0;
  z-index: 20;
  background-color: $color-brand-white;
  padding: $s $m;
  -webkit-transform: rotate(270deg);
  /* to support Safari and Android browser */
  -ms-transform: rotate(270deg);
  /* to support IE 9 */
  transform: translateY(60px);
  rotate: 270deg;
  border-radius: $xs;
  box-shadow: -8px 0px 8px 0px rgba(102, 102, 102, 0.08);
}

.feedback-modal-button {
  width: 100%;
  margin: $s 0;
}
.feedback-modal-button-section {
  display: flex;
  flex-direction: column;
}
.feedback-modal-text {
  font-size: $font-size-s;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $line-height-m;
  margin-bottom: $m;
}

.legal-menu-container {
  margin-left: $ml;
}

.legal-menu {
  width: 190px !important;
  margin-top: $xs !important;
}

.vandlpagecontainer {
  margin-top: 48px;

  a {
    background-color: $color-grey-20;
    padding: $s $m;
    margin: 0;
    font-size: $font-size-s;
    font-weight: $font-medium;
    line-height: $line-height-m;
    color: $color-black-primary;
  }
  .selected {
    background-color: $color-white-primary !important;
    border-top: $border-width-m solid $color-brand-black;
  }
}

.info-tabs-container {
  background-color: $color-white-primary;
  padding: 64px;
  @include media-mobile() {
    padding: $l $s;
  }
  @include media-tablet() {
    padding: $xl $m;
  }
  .version-container {
    display: flex;
    flex-direction: column;
  }

  h1 {
    display: block;
    font-weight: 700;
    font-size: 28px;
    margin: $ml 0;
  }
  p {
    line-height: $line-height-s;
    margin: $xs 0;
    font-size: $font-size-s;
  }
  h3 {
    font-size: $font-size-ml;
    margin: $s 0;
  }
  h2 {
    font-size: $font-size-ml;
    font-weight: $font-bold;
    line-height: normal;
  }
}

.legal-container {
  .legal-body {
    margin-top: $ml;
    p {
      line-height: $line-height-s;
      margin: $m 0;
      font-size: $font-size-s;
    }
  }
}

.legal-menu .menu-container commonux-menu-item .menu-item-container {
  height: auto !important;
}

commonux-brandplate .brand-plate {
  cursor: context-menu;
}
