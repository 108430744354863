@import '../../../../styles/main.scss';

.PDSRangeChart-Title {
  margin-top: $xl;
}
.PDSRangeChart {
  &-Title {
    font-size: $m;
    font-weight: 400px;
  }
  &-Container {
    position: relative;
    padding: $xxl 0;
  }
  &-Bar {
    display: flex;
    height: $xxl;
  }
  &-Item {
    height: 40px;
  }
  &-Block {
    height: $m;
  }
  &-Text {
    text-align: center;
  }
  &-Value {
    position: absolute;
    font-family: var(--font-family-abb);
    font-size: 12px;
    font-weight: 400;
    line-height: $m;
    letter-spacing: 0em;
    color: #696969;
    &:nth-child(odd) {
      top: 25px;
    }
    &:nth-child(even) {
      top: 45px;
    }
  }
  &-SolidValue {
    position: absolute;
    color: #1f1f1f;
    font-family: var(--font-family-abb);
    font-size: $m;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &-DottedLine {
    position: absolute;
    height: $ml;
    border: 1px dotted;
    width: 0;
  }
  &-SolidLine {
    position: absolute;
    height: 40px;
    border: 1px solid;
    width: 0;
  }
  &-Footer {
    &-Scale {
      height: 32px;
      width: 100%;
      background: linear-gradient(to top left, #e0f0ff 50%, white 50%);
    }
    &-Text {
      text-align: right;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &-Legend {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }
  &-LegendColor {
    height: $s;
    width: $s;
    margin-top: 5px;
    margin-right: 5px;
  }
  &-LegendText {
    color: #1f1f1f;
    font-family: var(--font-family-abb);
    font-size: 14px;
    font-weight: 400;
    line-height: $m;
    letter-spacing: 0em;
    text-align: left;
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
  }
}
