@import '../../../styles/main';

.workspace-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: 0.2s;
  gap: $ml;
  padding: 0 $ml;
}

.workspace-tab-container {
  width: 100%;
  margin-top: $s;

  .container-cl {
    margin-top: $s;

    @include media-tablet() {
      margin-top: $m;
    }

    @include media-mobile() {
      margin-top: $s;
    }
  }
}

.workspace-dimension-tab {
  background-image: url('../../../../public/selection-dimension-tab.svg');
  margin-right: $xs;
  background-repeat: no-repeat;
  background-position: $s;

  .label-container {
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px !important;
  }
}

.workspace-efficiency-tab {
  background-image: url('../../../../public/efficiency-tab.svg');
  margin-right: $xs;
  background-repeat: no-repeat;
  background-position: $s;

  .label-container {
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px !important;
  }
}

.workspace-configdrive-tab {
  @extend .workspace-efficiency-tab;
  background-image: url('../../../../public/driveconfig.svg');
}

.unhide-activetab {
  display: block !important;
}
.workspace-page-header {
  display: flex;
  flex-direction: column;
  font-weight: $font-medium;
  font-size: $ml;
  line-height: 36px;

  @include media-mobile() {
    font-size: $m;
    line-height: $ml;
  }

  .workspace-revision {
    font-weight: $font-regular;
    font-size: $font-size-xxs;
  }
}

.sidebar-container {
  width: 392px;
  position: sticky;
  z-index: 21;
  top: 66px;
  left: 0;
  transition: 0.2s;
  padding: $m 0;
  height: calc(100vh - 68px);

  commonux-tree-view .expand-true {
    padding-left: 0 !important;
  }

  @media all and (max-width: $tabletMaxWidth) {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #00000040;
    transition: ease 0.1s;
  }

  @include media-desktop() {
    width: 312px;
  }

  .sidebar-content {
    position: sticky;
    top: 82px;
    left: 0;
    background-color: $color-white-tertiary;
    overflow-y: auto;
    height: calc(100% - 148px);
    @media all and (max-width: $tabletMaxWidth) {
      width: 75%;
      height: 100%;
      padding: $m;
      transition: ease-in 0.3s;
      overflow-y: clip;
    }
  }

  .sidebar-mobile-close-button {
    display: none;
    position: absolute;
    right: -$l;

    @media all and (max-width: $tabletMaxWidth) {
      display: block;
    }
  }

  .sidebar-button-container {
    position: sticky;
    height: auto;
    right: 0;
    top: calc(92% - 56px);
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;

    @include media-tablet() {
      position: fixed;
      width: calc(25% + 32px);
    }
    @include media-mobile() {
      position: fixed;
      width: calc(25% + 32px);
    }
  }
}

.sidebar-collapsed {
  width: $xxl;

  @media all and (max-width: $tabletMaxWidth) {
    opacity: 0;
    width: 0;
    clear: both;
    overflow: hidden;
  }

  .sidebar-content {
    @media all and (max-width: $tabletMaxWidth) {
      opacity: 0;
      width: 0;
      clear: both;
      overflow: hidden;
    }
  }

  .sidebar-button-container {
    border-top: 2px solid $color-grey-10;
    width: fit-content;
    margin-right: auto;

    @include media-tablet() {
      display: none;
    }
    @include media-mobile() {
      display: none;
    }

    .sidebar-button {
      background: $color-white-primary;
      border-radius: $s;
      padding: $s;
      margin-top: $xs;
      margin-bottom: $s;
    }
  }

  .settings-cards-container {
    align-self: center;

    .setting-content {
      margin-right: 0;
    }
  }
}

.supply-data-text {
  font-weight: $font-medium;
  font-size: $font-size-m;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.supply-data-icon {
  margin-right: $s;
}

.application-text {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.app-selection-data {
  color: $color-text-black-secondary;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: $sm;

  font-weight: $font-regular;
  line-height: $line-height-s;
  width: 90%;
}
.application-device {
  padding-left: $ml;
  &:hover {
    border-radius: $xs;
    background: #00000008;
  }
  .loader-container {
    align-items: center;
    justify-content: flex-start;
  }
  commonux-tooltip {
    commonux-tree-view-node {
      .normal-text {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.application-device-motor {
  padding-left: $ml;
  &:hover {
    border-radius: $xs;
    background: #00000008;
  }
}

.workspace-content-container {
  display: flex;
  flex-direction: column;
  padding: $m 0 $xl 0;
  transition: margin-left 0.5s;
  width: 80%;
  gap: $m;

  @include media-mobile() {
    margin: 0;
    padding-left: $m;
    width: 100%;
  }

  @include media-tablet() {
    margin: 0 $m $m;
    padding: 0;
    width: 100%;
  }
}

.collapsed {
  width: 100%;

  @include media-mobile() {
    margin: 0;
    padding-left: $m;
  }

  @include media-tablet() {
    margin-left: $m;
  }
}

.settings-cards-container {
  display: flex;
  flex-direction: row;
  padding-bottom: $s;
  color: $color-text-black-secondary;
  text-decoration: none;
}

.settings-container {
  display: flex;
  flex-direction: column;
  padding-bottom: $s;
  margin-bottom: $s;
  box-shadow: 0px 1px 0px $color-text-white-secondary;
}

.setting-content {
  padding: $s;
  margin-right: $xs;
  font-weight: $font-medium;
  background: $color-white-secondary;
  border-radius: $s;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $color-white-primary;
    box-shadow:
      0 0 1px rgba(0, 0, 0, 0.08),
      0 0.5px 2px rgba(0, 0, 0, 0.16);
    color: $color-text-black-primary;
    fill: $color-text-black-primary;
  }

  @include media-mobile() {
    font-size: $font-size-s;
    line-height: $m;
  }
}

.setting-input-container {
  display: flex;

  .setting-input {
    width: 100%;
  }

  .setting-input-button {
    align-self: end;
    margin-left: $s;
    display: flex;
  }
}

.setting-icon {
  fill: $color-text-black-secondary !important;
  margin-bottom: $xs;
}

.add-system-button {
  margin-top: $s;
}

.selection-method-header {
  font-weight: $font-medium;
  font-size: $font-size-ml;
  line-height: $ml;

  @include media-mobile() {
    font-size: $m;
    line-height: $ml;
  }
}

.selection-card {
  display: flex;
  max-width: 650px;

  .card-container {
    margin-top: $m;
    @include media-mobile() {
      width: auto;
    }
  }

  .selection-header {
    font-size: $ml;
    font-weight: $font-medium;
    line-height: 36px;

    @include media-mobile() {
      font-size: $font-size-ml;
      line-height: 28px;
    }
  }

  .selection-image {
    padding-top: $ml;

    @include media-mobile() {
      width: 200px;
    }
  }

  .selection-button {
    margin-left: auto;

    @include media-mobile() {
      font-size: $font-size-s;
    }
  }

  .selection-content {
    font-size: $m;

    @include media-mobile() {
      font-size: $sm;
    }
  }
}

.segment-area {
  margin-top: $ml;

  .segment-method-header {
    font-weight: $font-medium;
    font-size: $font-size-ml;
    line-height: $ml;
    color: $color-text-black-secondary;

    @include media-mobile() {
      font-size: $m;
      line-height: $ml;
    }
  }

  .segment-card-container {
    grid-gap: $font-size-ml;
    align-items: stretch;
    @include grid-layout-with-breakpoints;
    grid-auto-rows: auto;

    @include media-mobile() {
      margin-right: 0;
    }

    .option-card {
      display: grid;
      grid-auto-rows: 1fr;

      .card-container {
        height: 350px;
        width: auto;
        justify-content: space-between;
        margin-top: $m;
        h6 {
          display: none;
          text-align: center;
          margin-top: 0;
        }

        #content {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          height: 120px;
          transition: height 0.6s;
        }

        &:hover {
          img {
            height: 80px;
          }

          h6 {
            display: block;
          }
        }
      }
    }
  }
}

.subtitle-container {
  content: '';
  display: block;
  border-bottom: 1px solid $color-grey-10;
}

.motor-selection-container {
  background-color: $color-white-primary;
  border-radius: $s;
  width: 595px;
  padding: $ml;
}

.efficiency-container {
  background-color: $color-white-primary;
  border-radius: $s;
  width: auto;
  padding: $xl;
  margin-top: $s;

  @media all and (max-width: $tabletMaxWidth) {
    padding: $m;
    margin: $s $m 0 0;
  }
}

.motor-selection-header {
  display: flex;
  justify-content: space-between;

  .disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  .motor-selection-header-text {
    display: flex;
    font-size: $font-size-ml;
    font-weight: $font-medium;

    .motor-selection-header-label {
      margin-left: $s;
    }
  }

  .motor-header-button-container {
    display: flex;
    align-items: center;
  }

  .motor-selection-restart-button {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .motor-selection-back-button {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: $m;
  }

  .motor-selection-restart-menu {
    position: absolute;
    top: $xxl;
    right: 0;
    width: fit-content;
    margin-right: $sm;
    z-index: 30;
  }
}

.subtitle-header {
  margin-top: $ml;
  font-size: $font-size-m;
  line-height: $line-height-xl;
  font-weight: $font-medium;
}

.select-header {
  font-weight: $font-medium;
  font-size: $font-size-s;
  margin-top: $m;
}

.select-container-area {
  margin-top: $s;
}

.motor-load-table-container {
  margin-top: $ml;
}

.motor-load-table {
  width: 100%;

  thead {
    color: $color-text-black-secondary;
    font-weight: $font-medium;
    font-size: $font-size-s;
    text-transform: uppercase;
    margin-bottom: $s;
    margin-left: $m;

    td {
      padding-left: $m;
      padding-bottom: $s;
    }
  }

  tbody {
    font-size: $font-size-s;

    tr {
      &:first-child {
        border-top: solid $color-text-black-primary $border-width-s;
      }
    }

    td {
      border: $border-width-s solid $color-white-primary;
      width: 25%;
      color: $color-text-black-secondary;
    }

    .property-col {
      font-weight: $font-regular;
      font-size: $font-size-s;
      color: $color-text-black-primary;
      background-color: $color-content-background-light-cloud;
    }

    .property-col-container {
      display: flex;
      justify-content: space-between;
      margin: 0 $s;
    }

    .property-text {
      width: 150%;
    }
    commonux-tooltip .tooltip-icon {
      width: 0 !important;
    }
    .property-text .required {
      color: $color-status-error;
    }

    .motor-load-table-input {
      padding: $s;
      border-radius: $xs;
      z-index: 9;

      padding-right: $font-size-xxs;
      text-align: left;
    }

    .unit-input {
      margin-left: -30px;
    }

    .input-suffix {
      display: flex;
    }

    .value-column {
      display: flex;
      border: $border-width-m solid $color-grey-20;
      justify-content: space-between;
      padding: $s;
      margin: $s;
      border-radius: $xs;

      .table-input {
        border: 0;
        outline: none;
        width: 100%;

        .table-input:focus {
          outline: none;
        }
      }

      .no-arrow::-webkit-outer-spin-button,
      .no-arrow::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .no-arrow {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      .unit-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 11px;
        font-weight: $font-medium;
      }
    }

    .background-grey {
      background-color: $color-grey-10;
      cursor: not-allowed;
    }
  }
}

.power-base-mandatory {
  border: $border-width-m solid $color-status-error !important;
}

.overload-time-info-container {
  margin-top: $ml;

  @include media-mobile() {
    display: flex;
    flex-direction: row;
  }
}

.overload-input {
  width: 150px;
  margin-right: $m;

  @include media-mobile() {
    width: 50%;
  }

  .suffix-container {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.overload-one-time-container {
  padding-top: $m;

  .overload-one-time-title-container {
    font-size: $font-size-m;
    line-height: $font-size-l;
    font-weight: $font-medium;
  }

  .overload-one-time-input-container {
    display: flex;
    padding-top: $s;

    .overload-one-time-input {
      width: calc(100% / 3);

      .top-label {
        width: max-content;
      }

      div > .container.medium .inputWrapper,
      div > .container.large .inputWrapper {
        padding: 0 0 0 $xs;
      }
    }

    .overload-one-time-input-margin {
      margin-right: $s;
    }

    .text-input {
      font-size: $font-size-xs !important;
      width: max-content;
      padding: 0 $s 0 $s;
    }
  }
}

.add-duty-cycle-button-container {
  margin-top: $ml;
  margin-bottom: $ml;

  .add-duty-cycle-button {
    font-size: $font-size-s;
    font-weight: $font-medium;
  }
}

.motor-reset-label {
  font-size: $font-size-s;
  line-height: $line-height-xl;
  font-weight: $font-medium;
  margin-left: 4px;
}

.motor-specification-container {
  margin-top: $ml;

  .motor-specification-header {
    font-size: $font-size-m;
    line-height: $line-height-xl;
    font-weight: $font-medium;
  }

  .motor-specification-input-text {
    margin-top: $m;
  }

  .motor-specification-input {
    width: 100%;
  }
}

.drive-count-container {
  margin-top: $m;
}

.motors-count-container {
  margin-top: $m;
}

.right-header {
  display: flex;
  justify-content: space-between;

  .right-header-text {
    font-size: $font-size-ml;
    font-weight: $font-medium;
  }

  .right-header-button-container {
    display: flex;
  }
}

.type-product-info-table-header {
  display: none;
}

.type-product-info-table {
  width: 100%;
  border-collapse: collapse;

  .type-product-row {
    border-bottom: 1px solid $color-grey-10;
  }

  .type-product-col {
    font-size: $font-size-s;
    line-height: $line-height-m;
    color: $color-text-black-secondary;
    height: $xl;
  }

  .value-col {
    color: $color-text-black-primary;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: $font-medium;
    margin-right: $s;
  }
}

.result-tabs {
  font-size: $font-size-s;
  line-height: $line-height-m;
  font-weight: $font-regular;

  .result-tab-item {
    width: max-content;
  }
}

.drive-result-table {
  font-size: $font-size-s;
  line-height: $line-height-m;
  font-weight: $font-regular;
}

.catalogue-table {
  width: 100%;
  margin-top: $m;

  .catalogue-row {
    height: $l;
    color: $color-text-black-secondary;
  }

  tr:nth-child(even) {
    background-color: $color-content-background-light-cloud;
  }

  .catalogue-col {
    width: 50%;
    padding-left: $m;
  }

  .catalogue-value-col {
    text-align: right;
    width: 50%;
    padding-right: $m;
  }
}

.selection-data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: $m;

  .selection-data-table-header-container {
    color: $color-text-black-secondary;
    font-weight: $font-medium;
    font-size: $font-size-s;
    text-transform: uppercase;
    margin-bottom: $s;
    margin-left: $m;

    td {
      width: 25%;
      height: $l;
      border-bottom: $border-width-m solid $color-grey-50;
    }
  }

  .selection-data-table-subheader-container {
    th {
      text-align: left;
      width: 100%;
      font-size: $font-size-s;
      line-height: $line-height-m;
      color: $color-text-black-secondary;
      font-weight: $font-regular;
      height: $l;
      border-bottom: $border-width-s solid $color-grey-10;
      background-color: $color-content-background-light-cloud;
      padding-left: $m;
    }
  }

  .selection-data-table-row {
    th {
      width: 25%;
      text-align: left;
      font-size: $font-size-s;
      line-height: $line-height-m;
      color: $color-text-black-secondary;
      font-weight: $font-regular;
      height: $l;
      border-bottom: 1px solid $color-grey-10;
      background-color: $color-content-background-light-cloud;
      padding-left: $m;
    }

    td {
      width: 25%;
      height: $l;
      border-bottom: $border-width-s solid $color-grey-10;
      padding-left: $m;
    }
  }
}

.efficiency-button {
  color: $color-brand-white;
  font-weight: $font-medium;
}

.vsdRating-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: $m;

  .vsdRating-table-header {
    th {
      color: $color-text-black-secondary;
      font-weight: $font-medium;
      font-size: $font-size-s;
      margin-bottom: $s;
      margin-left: $m;
      text-transform: uppercase;
      width: 25%;
      height: $l;
      border-bottom: $border-width-m solid $color-grey-50;
    }
  }

  .vsdRating-table-rows {
    th {
      width: calc(100% / 3);
      text-align: left;
      font-size: $font-size-s;
      line-height: $line-height-m;
      color: $color-text-black-secondary;
      font-weight: $font-regular;
      height: $l;
      border-bottom: $border-width-s solid $color-grey-10;
      background-color: $color-content-background-light-cloud;
      padding-left: $m;
    }

    td {
      width: calc(100% / 3);
      height: $l;
      border-bottom: $border-width-s solid $color-grey-10;
      padding-left: $m;
    }
  }
}

.button-group {
  .select-motors-button {
    border-radius: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .select-all-button {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .manual-selection-button {
    border-radius: 100px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    cursor: pointer;
  }

  .button-group-common {
    color: $color-grey-20;
    line-height: $line-height-s;
    font-weight: $font-medium;
    font-size: $font-size-m;
    background-color: #000000e0;
    width: calc(100% / 3);
    cursor: pointer;
  }

  .divider {
    border-left: $border-width-s solid $color-brand-white;
    margin-top: $xs;
    margin-bottom: $xs;
    cursor: pointer;
  }

  .disabled {
    color: $color-text-white-disabled;
    cursor: auto;
  }
}

.motor-load-table-mobile {
  @include media-mobile() {
    width: 100%;

    .motor-load-table-mobile-header {
      color: $color-text-black-secondary;
      font-weight: $font-medium;
      font-size: $font-size-s;
      text-transform: uppercase;
      height: $l;

      td {
        padding-left: $m;
        width: calc(100% / 3);
        height: $l;
      }
    }

    .motor-load-table-mobile-subheader {
      background-color: $color-status-unknown-background;
      margin-top: 6px;

      &:first-child {
        th {
          border-top: solid $color-text-black-primary $border-width-s;
        }
      }

      th {
        font-size: $font-size-s;
        line-height: $line-height-m;
        font-weight: $font-regular;
        text-align: left;
        height: $l;
        padding: $xs;

        .subheader-title {
          font-weight: $font-medium;
          color: $color-text-black-primary;
        }

        .subheader-desc {
          font-size: $font-size-xs;
          color: $color-text-black-secondary;
        }
      }
    }

    .value-column {
      display: flex;
      border: $border-width-m solid $color-grey-20;
      justify-content: space-between;
      padding-top: $s;
      padding-bottom: $s;
      padding-left: $xs;
      padding-right: $xs;
      margin: $xs;
      border-radius: $xs;

      .input-unit-container {
        display: flex;
      }

      .table-input {
        border: 0;
        outline: none;
        width: 100%;

        .table-input:focus {
          outline: none;
        }
      }

      .no-arrow::-webkit-outer-spin-button,
      .no-arrow::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .no-arrow {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      .unit-container {
        width: 100%;
        font-size: $font-size-s;
        display: flex;
        justify-content: flex-end;
      }
    }

    .noneditable-container-column {
      padding: $xs;
    }

    .noneditable-container {
      display: flex;
      border: $border-width-m solid $color-grey-20;
      justify-content: space-between;
      border-radius: $xs;
      height: $l;
      padding: 2px $xs;
    }

    .noneditable-value-column {
      font-size: $font-size-s;
      line-height: $line-height-m;
      font-weight: $font-regular;
      text-align: left;
      padding: $xs;
    }

    .background-grey {
      background-color: $color-grey-10;
      cursor: not-allowed;
    }
  }
}
.report-image-container {
  width: 100%;
  margin: auto;
  height: 100%;
  max-height: 350px;
}

.report-chart-container {
  opacity: 0;
  height: 0;
}

a[disabled],
button[disabled],
html input[disabled] {
  cursor: not-allowed !important;
}

.tab-icon-container {
  display: flex;
  flex-direction: row;
}

.drive-load-input-container {
  margin-top: $ml;
  display: flex;
  flex-direction: row;

  @include media-mobile() {
    display: flex;
    flex-direction: row;
  }
}

.drive-load-input {
  width: 50%;
  margin-right: $m;

  @include media-mobile() {
    width: 50%;
  }

  .suffix-container {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.overload-header {
  margin-top: $m;
  font-size: $font-size-s;
  line-height: $line-height-m;
  font-weight: $font-medium;
}

.drives-overload-time-info-container {
  margin-top: $s;

  @include media-mobile() {
    display: flex;
    flex-direction: row;
  }
}

.selection-page-container {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: $color-brand-white;
  padding: $xl;

  @include media-mobile() {
    padding: $m;
  }
}

.application-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $s;

  @include media-mobile() {
    flex-direction: column-reverse;
    gap: $m;
    margin-bottom: $ml;
  }
}

.application-continue-button {
  @include media-mobile() {
    text-align: right;
  }
}

.application-step-container {
  font-size: $font-size-s;
  line-height: $m;
  text-transform: uppercase;
  margin-bottom: $s;
}

.industry-selection-header {
  font-size: $font-size-ml;
  font-weight: 600;
  line-height: 25px;
}

.industry-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: $xl;
  grid-gap: $m;
  place-content: flex-start;

  @include media-mobile() {
    place-content: space-evenly;
  }

  @include media-tablet() {
    place-content: space-around;
  }
}

.industry-details-container {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey-20;
  width: 148px;
  height: 148px;
  border-radius: $s;
  padding: $m;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  @include media-mobile() {
    width: 120px;
    height: 120px;
  }

  &:hover {
    transition: 0.3s ease-in-out;
    box-shadow:
      0px $s $m 0px rgba(0, 0, 0, 0.12),
      0px 2px $xs 0px rgba(0, 0, 0, 0.08),
      0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  }
}

.industry-image-container {
  width: $xxl;
  height: $xxl;
  padding-bottom: $s;
  align-self: center;
}

.industry-label {
  font-weight: $font-medium;
  font-size: $font-size-s;
  line-height: $m;
}

.selection-step-button {
  margin-right: $sm;
}

.ratings-voltage-header {
  font-size: $font-size-s;
  font-weight: $font-medium;
  line-height: $font-size-ml;
  margin-bottom: $s;
}

.selection-select-area {
  width: 480px;

  .select-container .suffix-container {
    flex: initial;
  }

  .text-input {
    width: max-content;
  }

  @include media-mobile() {
    width: 100%;
  }
}

.ratings-container {
  display: flex;
  flex-direction: column;
  margin-top: $xl;
  grid-gap: $ml;
  width: 480px;

  @include media-mobile() {
    width: 100%;
  }

  @include media-tablet() {
    width: 100%;
  }
}

.ratings-overload-header {
  font-size: $font-size-s;
  font-weight: $font-medium;
  line-height: $font-size-ml;
  margin-bottom: $s;
}

.ratings-radio-container {
  display: flex;
  flex-direction: column;
  grid-gap: $m;
}

.ratings-radio-label {
  font-size: $font-size-s;
  line-height: $font-size-ml;
  font-weight: $font-medium;
}

.ratings-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: $l;
}

.ratings-button {
  margin-left: $s;
}

.add-duty-cycle-modal {
  padding-left: $ml;
  padding-right: $ml;
  z-index: 20;

  .dialog {
    max-width: 940px !important;
  }

  .add-duty-cycle-modal-content {
    max-width: 2000px !important;
  }

  .rms-power-data-container {
    display: flex;
    padding-top: $m;
  }

  .rms-data-container {
    display: flex;
    padding: $m;
    background-color: $color-content-background-light-cloud;
    margin-right: $m;
  }

  .power-details-container {
    display: flex;
    padding: $m;
    background-color: $color-content-background-light-cloud;
  }

  .rms-data-labels {
    font-size: $font-size-xxs;
    line-height: $sm;
    font-weight: $font-regular;
  }

  .power-details-label {
    font-size: $font-size-xxs;
    line-height: $sm;
    font-weight: $font-regular;
  }

  .rms-data-value {
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
  }

  .power-data-value {
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
  }

  .rms-container {
    display: flex;
    flex-direction: column;
    margin-right: $m;
    width: $xxl;
  }

  .rms10-container {
    display: flex;
    flex-direction: column;
    margin-right: $m;
    width: $xxl;
  }

  .highest-load-container {
    display: flex;
    flex-direction: column;
    width: 61px;
  }

  .base-power-container {
    display: flex;
    flex-direction: column;
    margin-right: $m;
    width: 66px;
  }

  .cont-load-container {
    display: flex;
    flex-direction: column;
    margin-right: $m;
    width: 60px;
  }

  .power-overload-container {
    display: flex;
    flex-direction: column;
  }

  .torque-power-radiobutton-container {
    display: flex;
    padding-top: $m;

    .torque-option {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-right: 0;
    }

    .power-option {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    ion-radio-group {
      gap: $s;
    }
  }

  .load-points-container {
    padding-top: $m;
  }

  .load-points-title {
    font-size: $font-size-m;
    line-height: $line-height-xl;
    font-weight: $font-medium;
  }

  .load-points-table {
    padding-top: $s;
    border-collapse: collapse;

    .load-points-table-header-container {
      color: $color-text-black-secondary;
      font-weight: $font-medium;
      font-size: $font-size-s;
      line-height: $line-height-m;
      text-transform: uppercase;
      margin-bottom: $s;
      margin-left: $m;

      td {
        height: $l;
        border-bottom: $border-width-s solid $color-grey-50;
      }
    }
  }

  .load-points-cell {
    width: calc(100% / 6);
    padding-left: $m;
    padding-right: $m;
  }

  .load-point-input-cell {
    width: 142px;
  }

  .load-points-value-column {
    display: flex;
    border: $border-width-m solid $color-grey-20;
    justify-content: space-between;
    padding: $s;
    margin: $s;
    border-radius: $xs;

    .no-arrow::-webkit-outer-spin-button,
    .no-arrow::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .no-arrow {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    .load-points-table-input {
      border: 0;
      outline: none;
      width: 100%;

      .load-points-table-input:focus {
        outline: none;
      }
    }
  }

  .load-points-table-unit-container {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    color: $color-text-black-disabled;
  }
  .dutycycle-delete-button {
    cursor: pointer;
  }

  .dutycycle-delete-button-container {
    border-right: none !important;
  }

  .description-title {
    min-width: 93px;
  }

  .description-column {
    min-width: 93px;
    background-color: $color-content-background-light-cloud;
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
  }

  .add-overload-button-container {
    width: 100%;
    padding-top: $s;

    .add-overload-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .load-graph-container {
    padding-top: $m;
  }

  .load-graph-title {
    font-size: $font-size-m;
    line-height: $line-height-xl;
    font-weight: $font-medium;
  }

  .add-duty-cycle-modal-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.img-and-desc-container {
  margin-top: $m;
  display: flex;
  flex-direction: row;

  .img-container {
    max-height: 154px;

    img {
      max-width: 160px;
      min-width: 110px;
      width: 100%;
      height: 100%;
    }
  }

  .desc-container {
    margin-left: $m;
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    content: 'Show more';
  }

  .desc-container-read-more {
    width: 400px;
    margin-left: $m;
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
  }

  .view-catalog-button {
    margin-left: $xs;
  }

  .label-title {
    font-weight: $font-bold;
  }

  .label-content {
    display: flex;
  }
}

.result-notification-container {
  font-size: $font-size-s;
  font-weight: $font-regular;
  padding-top: $s;
  padding-left: $s;
  color: $color-text-black-secondary;
  display: flex;
}
.result-notification-ifno-icon {
  margin-right: $s;
}
.selected-method-text {
  font-size: $font-size-s;
  font-weight: $font-bold;
  padding-top: $s;
  padding-left: $s;
}

.link-buttons-container {
  margin-top: $m;
}

.form-loader {
  background-color: rgba(0, 0, 0, 0.028);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  padding-top: 50%;
  z-index: 4;
}

.result-graph-container {
  display: flex;
  flex-direction: column;
  background-color: lightgray 50%;

  .graph-title {
    font-size: $font-size-m;
    font-weight: $font-medium;
    line-height: $line-height-xl;
    margin-bottom: $m;
  }
}

.selection-radio {
  margin-right: $s;
  height: $l;
}

.progressbar-container {
  display: flex;
  flex-direction: column;
}

.progressbar-header {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  font-size: $sm;
  margin-bottom: $xs;
}
.bigText {
  font-size: $m !important;

  font-weight: $font-regular;
  line-height: $line-height-m;
  margin-bottom: $s;
}

.progressbar-footer .bigText {
  margin-top: $s;
}
.progressbar-content {
  position: relative;

  //overwriting progressbar
  .primary-black {
    background-color: #0ca919 !important;
  }
}

.progressbar-background {
  border: 5px solid #e7e7e7;
  background-color: #e7e7e7;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
}

.progressbar-progress {
  position: absolute;
  box-sizing: border-box;
  border-top: 5px solid $color-green-100;
  border-bottom: 5px solid $color-green-100;
  background-color: $color-green-100;
  transition: width 0.5s;
}

.progressbar-footer {
  display: flex;
  justify-content: space-between;
  color: $color-text-black-secondary;
  margin-top: $xs;
  font-size: $sm;
}

.efficiency-header {
  color: var(--text-black-primary, $color-text-black-primary);
  font-size: $font-size-ml;
  font-weight: $font-medium;
  margin-bottom: $ml;
}

.cdm-heading {
  padding: $m 0;
  margin: $m 0;
  border: 1px solid $color-grey-20;
  border-left: 0;
  border-right: 0;
  font-size: $sm;
}

.pds-class-info {
  margin-top: $s;
  font-size: $sm;
}

.pds-content {
  border-bottom: 1px solid $color-grey-20;
  margin-bottom: $ml;
  padding-bottom: $m;
}

.data-rows:nth-child(even) {
  color: var(--grey-90, $color-text-black-primary);
  border: 1px solid #bababa;
  border: 1px solid var(--grey-10, #ebebeb);
  background: var(--theme-background-cloud, $color-white-tertiary);
  border-left: 0;
  border-right: 0;
}

.data-rows {
  padding: 10px $m;
  border-radius: $xs;
}

.losses-result-section-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $s;
  border-bottom: 1px solid var(--grey-30, #ebebeb);
  background: var(--black-opacity-2, rgba(0, 0, 0, 0.04));
}

.losses-result-section-button {
  margin-right: $l;
  padding: $s;
  cursor: pointer;
}

.loss-data-type {
  color: $color-text-black-secondary;
}

.losses-result-section {
  transition: all 0.5s ease-in-out;
}

.losses-result-section-header-active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $s;
  border-bottom: 1px solid var(--grey-30, #bababa);
  background: var(--black-opacity-6, rgba(0, 0, 0, 0.12));
}

.workspace-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-items-container {
  width: max-content;
  position: absolute;
  z-index: 10;
  top: 45px;
  left: 22%;
}

.pds-img {
  width: $m;
  margin-right: 6px;
}

.transformer-info-icon {
  width: $m !important;
  margin-right: $s;
}

.tab-group {
  width: 100%;
  padding: 0;
}

commonux-tab-item > .navigation-container.primary-black.disabled .icon-color {
  cursor: not-allowed;
}

commonux-tab-item {
  display: flex;
}
.energy-losses-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.energy-losses-table thead,
.energy-losses-table .header-row {
  border-collapse: inherit;
  border-spacing: 0;
  width: 100%;
  border: 0;
  color: $color-text-black-secondary;
  white-space: nowrap;
  padding: $s;
}

.energy-losses-table thead th {
  text-transform: uppercase;
  font-size: $sm;
  color: $color-text-black-secondary;
  background-color: white;
  text-align: left;
}

.energy-losses-table th {
  text-align: right;
  padding: $sm 7px;
  border: 0;
  display: table-cell !important;
}

.energy-losses-table tbody {
  border-collapse: inherit;
  border-spacing: 0;
  width: 100%;
  border-left: 2px solid $color-text-black-primary;
  border-right: 2px solid $color-text-black-primary;
  border-color: $color-text-black-primary currentcolor;
  border-style: solid;
  border-width: 2px 0;
}

.energy-losses-table tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.energy-losses-border tr {
  background-color: #fff !important;
}
.energy-losses-border thead th {
  text-transform: capitalize !important;
}
.energy-losses-table td {
  padding: $sm;
  border-left: 1px solid #bababa;
  border-right: 1px solid #bababa;
  border-color: #bababa currentcolor;
  border-style: solid;
  border-width: 1px 0;
  white-space: nowrap;
}

.energy-losses-table td:nth-child(3),
.energy-losses-border td:nth-child(5) {
  border-right: 1px solid var(--grey-30, #bababa);
}

.energy-losses-border td:nth-child(3),
.energy-losses-border td:nth-child(5),
.energy-losses-border td:nth-child(7) {
  border-right: 1px solid var(--grey-30, #bababa);
}
.energy-losses-table tr {
  font-weight: $font-medium;
  color: $color-text-black-primary;
}
.left-align td {
  text-align: left !important;
}
.standard-points-table td:nth-child(5) {
  border-right: none;
}

.standard-points-table tbody {
  border-left: 1px solid var(--grey-30, #bababa);
}

.color-table-border tr:nth-child(-n + 8) td:first-child {
  border-left: 2px solid rgb(27, 122, 194);
}

.color-table-border tr:nth-child(n + 9) td:first-child {
  border-left: 2px solid rgb(237, 87, 57);
}

.device-recommendation-container {
  display: flex;
  width: 100%;
  margin: $xl 0;
  gap: $ml;

  @include media-mobile() {
    flex-direction: column;
    margin: $ml 0;
    gap: 0;
  }
}

.product-selection-container {
  flex: 1;
  padding: $m;
  grid-gap: $ml;
  cursor: pointer;

  &:hover {
    border-radius: $s;
    box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.2),
      0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  }
}

.disabled {
  box-shadow: none !important;
  border-radius: none !important;
  cursor: initial;
}

.product-type-header {
  padding-top: $m;
  color: $color-text-black-secondary;
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: $m;
}

.product-name-header {
  color: var(--text-black-primary, $color-text-black-primary);
  font-size: $font-size-ml;
  font-weight: $font-medium;
  line-height: $ml;
}

.selection-product-desc {
  color: var(--text-black-primary, $color-text-black-primary);
  font-size: $m;
  font-weight: $font-regular;
  line-height: $l;
}

.product-feature-header {
  color: $color-black-primary;
  font-size: $m;
  font-weight: $font-medium;
  line-height: 125%;
  margin-bottom: $m;
}

.feature-selection-container {
  flex: 1;
  padding: $m;
  grid-gap: $ml;
}

.product-feature-description {
  background: var(--white-secondary, #fafafa);
  padding: $m;
  color: $color-black-primary;
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: $font-size-ml;
}

.product-recommendations {
  flex-direction: column;
  gap: 0;
}

.product-recommendations-container {
  display: flex;
  flex-direction: row;
  gap: $m;
  margin-top: $m;
  overflow-x: scroll;
}

.product-data-container {
  padding: $m;
  border-radius: $s;
  border: 1px solid var(--text-white-secondary, $color-grey-20);
  flex: 1;
}

.selected-product {
  background-color: var(--theme-background-cloud, $color-white-tertiary);
}

.product-data-header {
  margin: $s 0;
  color: var(--text-black-primary, $color-text-black-primary);
  text-align: center;
  font-size: $font-size-ml;
  font-weight: $font-medium;
  line-height: $ml;
}

.product-data-info-container {
  padding: $ml 0;
  display: flex;
  flex-direction: column;
  gap: $sm;
  color: $color-black-primary;
  text-align: center;
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: $font-size-ml;
  border-bottom: 1px solid $color-grey-20;
}

.product-main-image-container {
  text-align: left;

  @include media-mobile() {
    text-align: center;
  }
}

.product-main-image {
  height: 150px;
}

.product-data-image-container {
  text-align: center;
}

.product-data-image {
  width: 195px;
  height: 145px;
}

.show-more-label {
  margin-left: $font-size-ml;
  color: var(--text-black-primary, $color-text-black-primary);
  text-align: center;
  font-size: $font-size-s;
  font-weight: $font-medium;
  line-height: $m;
}

.product-data-urls-container {
  padding-top: $ml;
  text-align: center;
}

.product-data-recommended-label {
  position: relative;
  width: max-content;
  padding: $xs $s;
  border-radius: $s;
  opacity: 0.8;
  background: var(--theme-background-graphite, #333);
  box-shadow:
    0px 0.5px 2px 0px rgba(0, 0, 0, 0.16),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  color: var(--text-white-primary, #fff);
  text-align: center;
  font-size: $font-size-xxs;
  font-weight: $font-regular;
  line-height: $sm;
  margin: -$s;
}

.efficiency-page-headers {
  color: #000;
  font-size: $m;
  font-weight: $font-medium;
  margin: $xl 0 $s 0;
}

.efficiency-page-table-msg {
  color: $color-text-black-secondary;
  font-size: $sm;
  font-weight: $font-regular;
  margin-top: $s;
}

.device-motivators-container {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;

  .collapsed {
    padding-left: 0;
    margin-left: 0;
  }
}

.left-pane-container {
  overflow: hidden;
  overflow-y: auto;
  max-height: 98%;

  @include media-tablet() {
    max-height: 85%;
  }

  @include media-mobile() {
    max-height: 80%;
  }

  .transformer-container {
    @include media-mobile() {
      font-size: $font-size-s;
    }
  }

  .all-applications-container {
    display: flex;
    flex-direction: column;
    margin-right: 2px;
  }
  .application-container {
    border-radius: $s;
    padding: 0 $s $s;
    font-size: $font-size-s;
    cursor: pointer;
    margin-bottom: $s;

    &:hover {
      background: $color-white-secondary;
    }

    .application-header {
      .hover-primary-default {
        &:hover {
          background-color: #00000029;
        }
      }
      :not(.expand-true) > .hover-primary-default {
        &:hover {
          background-color: initial;
        }
      }

      .status-menu-container {
        position: absolute;
        right: $s;
        z-index: 100;
      }
    }

    .headline {
      margin-bottom: 0;
      line-height: 0;
    }

    .device-container {
      .icon-box {
        display: none;
      }

      .icon-box-noExpand {
        display: none;
      }
    }

    .status-icon-hover {
      z-index: 99;
    }
  }

  .transformer-container {
    border-radius: $s;
    font-size: $font-size-s;
    cursor: pointer;
    margin-bottom: $s;

    commonux-tree-view .headline {
      padding-bottom: 0 !important;
    }

    &:hover {
      background: $color-white-secondary;
    }
  }

  .application-active {
    background: var(--white-primary, #fff) !important;
    box-shadow:
      0px 0.5px 2px 0px rgba(0, 0, 0, 0.16),
      0px 0px 1px 0px rgba(0, 0, 0, 0.08);

    .active {
      background: #00000014;
      border-radius: $xs;
    }
  }

  .transformer-active {
    background: #00000014 !important;
  }

  .error-status {
    fill: $color-status-error !important;
    &:hover {
      border-radius: $xs;
      background: #00000008;
    }
  }
  .warning-status {
    fill: $color-status-warning !important;
  }

  .warning-status-application {
    fill: $color-status-warning !important;
    background: $color-status-warning !important;
    border-radius: 20px;
    width: $s !important;
    height: $s !important;
    margin-right: $xs;
  }
  .error-status-application {
    fill: $color-status-error !important;
    background: $color-status-error !important;
    border-radius: 20px;
    width: $s !important;
    height: $s !important;
    margin-right: $xs;
  }
}

.application-menu-container {
  position: absolute;
  max-width: 200px;
  width: 180px;
  top: -$xs;
  right: $s;
  z-index: 999;
}

.add-application-button {
  position: sticky;
  height: auto;
  right: 0;
  border-top: 2px solid #ebebeb;
  top: 92%;

  @include media-mobile() {
    position: fixed;
    left: 0;
    width: 75%;
    top: 90%;
  }

  @include media-tablet() {
    position: fixed;
    left: 0;
    width: 75%;
  }
}

.report-page-copyright-content {
  color: #686868;
  text-align: center;
  font-size: $font-size-s;

  font-weight: $font-regular;
  line-height: 14px;
  margin-top: 160px;
}

.report-modal-container {
  position: absolute;
  z-index: 24;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000040;

  .report-modal-header {
    display: flex;
    justify-content: space-between;
    padding: $m $ml;
    border-bottom: 1px solid var(--grey-30, #bababa);
    background: var(--theme-background-snow, #fff);

    .report-modal-header-label {
      color: var(--brand-colors-black, #000);
      font-size: $m;

      font-weight: $font-medium;
      line-height: $ml;
      align-self: center;
    }
  }

  .report-modal-header-content-container {
    padding: $ml 0 $s;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 74px);
    width: 100%;
    max-width: calc(1440px - 48px);

    @include media-mobile() {
      flex-direction: column-reverse;
    }
    .report-modal-content-left-side {
      display: flex;
      justify-content: space-between;
      gap: $l;
    }

    .report-modal-header-content {
      width: 100%;
      padding-bottom: $ml;
      overflow: auto;
      margin: 0 32px 0 0;
      max-width: 100%;
      transition: all 0.3s linear;
      @include media-mobile() {
        width: 100%;
        margin-top: $ml;
      }
    }
    .report-modal-header-content-iscontentopen {
      max-width: 585px !important;
      margin: 0 32px;
      transition: all 0.3s linear;
    }

    .report-modal-header-comments-container {
      // width: 100%;
      width: 408px;
      min-width: 408px;
      height: fit-content;

      padding: $ml;
      border-radius: $s;
      background: var(--theme-background-snow, #fff);
      box-shadow:
        0px$xs $s 0px rgba(0, 0, 0, 0.08),
        0px 0.5px 2px 0px rgba(0, 0, 0, 0.08),
        0px 0px 1px 0px rgba(0, 0, 0, 0.08);

      @include media-mobile() {
        width: 100%;
      }

      .report-modal-comments-container {
        .report-modal-header-comments-title {
          color: var(--brand-colors-black, #000);
          font-size: $m;

          font-weight: $font-medium;
          line-height: $ml;
          padding-bottom: $m;
          border-bottom: 2px solid $color-grey-20;
        }

        .report-modal-button-icon {
          margin-right: 4px;
        }

        .report-modal-header-comments-input-container {
          margin-top: $m;
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .report-modal-header-comments-input {
            width: 100%;
          }
        }
      }
    }
  }
}

.expand-graph-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.expand-graph {
  cursor: pointer;
}

.expand-result-graph-modal {
  .dialog {
    min-width: 930px;
    width: auto;
  }
}

.report-graph-modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.losses-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.standByLosses {
  margin-top: $ml;
}
.standByLosses .energy-losses-table td:nth-child(1) {
  text-align: left !important;
}
.standByLosses .energy-losses-table td,
.standByLosses .energy-losses-table th {
  text-align: left !important;
  text-transform: uppercase !important;
}
.manual-selection-modal {
  .dialog {
    max-width: 1440px !important;
    width: 90%;

    .content {
      overflow-x: auto !important;
      width: calc(100% - $ml); //set it to 100 % if we dont want horzantal
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-left: $ml; //comment this if we dont want horizantal bar
      @include custom-scrollbar($isHorizantalscrollrequired: true);
    }
  }
}

.manual-selection-motor-modal-content {
  min-height: 750px;

  .form-loader {
    padding-top: 0%;
    .loading-indicator-container {
      top: 50%;
    }
  }
}

.manual-selection-table {
  width: 100%;
  tr {
    height: 40px;
  }

  tr:nth-child(even) {
    background-color: var(--grey-30, #ebebeb);
  }
  tr:first-child {
    color: $color-text-black-secondary;
    font-weight: $font-medium;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #f5f5f5 !important;
    border-bottom: 1px solid var(--brand-colors-black, #000);
  }

  td:nth-child(3),
  td:nth-child(5) {
    border-right: 0px;
  }
  td {
    width: auto;
    min-width: 36px;
    white-space: nowrap;
    padding-left: $m;
    padding-right: $m;
  }
}

.tr-selected {
  background-color: #848484 !important;
}

.manual-selection-drive-modal-content {
  min-height: 750px;

  .form-loader {
    padding-top: 0%;
    .loading-indicator-container {
      top: 50%;
    }
  }
}

.manual-selection-confirmation-modal {
  .dialog {
    min-width: 536px;
    width: auto;

    .content {
      width: 100%;
    }
  }
}

.confirmation-title-container {
  display: flex;
  margin-bottom: $m;
}

.confirmation-title-text {
  font-size: $font-size-m;
  line-height: $line-height-xl;
  font-weight: $font-medium;
}

.drive-icon {
  margin-right: $s;
}

.confirmation-modal-description {
  font-size: $font-size-s;
  line-height: $line-height-m;
  font-weight: $font-regular;
  margin-right: $m;
  margin-left: $m;
}

.confirmation-modal-content {
  min-width: 490px;
}

.confirmation-modal-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.confirmation-modal-select-button {
  margin-left: $s;
}

.workspace-reports-menu {
  position: absolute;
  right: $m;
  width: 240px;
  margin-top: $xxl;
  z-index: 999;
}

.error-dialogue-container {
  display: flex;
  flex-direction: row;
  background-color: #fbeceb;
  align-items: center;
  border-radius: $s;
  margin-top: $l;
  margin-bottom: $l;
}

.warning-dialogue-container {
  display: flex;
  flex-direction: row;
  background-color: #f8f1ee;
  align-items: center;
  border-radius: $s;
  margin-top: $l;
  margin-bottom: $l;
}

.error-icon-color {
  margin: $m;
  fill: #ef3a34 !important;
}

.message-container {
  margin-top: $m;
  margin-bottom: $m;
  margin-right: $m;
  font-weight: $font-medium;
  font-size: $font-size-s;
}

.warning-icon-color {
  margin: $m;
  fill: #fc831d !important;
}

.transformer-data-selection-header {
  display: flex;
  flex-direction: row;
  font-size: $font-size-ml;
  font-weight: $font-medium;
}

.transformer-data-selection-header-text {
  margin-left: $s;
}

.transformer-subsection-header {
  font-weight: $font-medium;
  font-size: $font-size-m;
  margin-top: $m;
}

.transformer-select-header {
  margin-top: $m;
  font-size: $font-size-s;
  font-weight: $font-medium;
  margin-bottom: $s;
}

.transformer-input-text {
  margin-top: $m;
  font-size: $font-size-s;
  font-weight: $font-medium;
  width: 100%;
  .text-input {
    font-size: $font-size-xs !important;
    width: max-content;
    padding: 0 $s 0 $s;
  }
}

.transformer-subtitle-container {
  content: '';
  display: block;
  border-bottom: 1px solid $color-grey-10;
  padding-top: $m;
  padding-bottom: $m;
}

.dsu-load-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dsu-input-text {
  margin-top: $m;
  font-size: $font-size-s;
  font-weight: $font-medium;
  margin-right: $m;
  width: 50%;
}

.calculated-input-text {
  margin-top: $m;
  font-size: $font-size-s;
  font-weight: $font-medium;
  width: 50%;
}
.transformer-result-title {
  font-size: $font-size-ml;
  font-weight: $font-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: 100%;
  }
}

.transformer-result-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transformer-result-selection-data-table-name {
  font-size: $font-size-s;
  font-weight: $font-regular;
  color: $color-text-black-secondary;
  width: 50%;
  padding-left: $m;
  padding-top: $xs;
  padding-bottom: $xs;
  text-align: left;
}

.transformer-result-selection-data-table-value {
  font-size: $font-size-s;
  font-weight: $font-regular;
  color: $color-text-black-secondary;
  padding-right: $m;
  text-align: right;
  width: 50%;
}

.selection-data-table {
  tr:nth-child(even) {
    background-color: $color-white-tertiary;
  }
}

.transformer-result-selection-data-table-row {
  height: $l;
}

.motor-result-loader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $m;
}

.motor-result-loader-message {
  margin-left: $s;
  font-size: $font-size-s;
  font-weight: $font-bold;
}

.catalogue-commonLoader {
  display: flex;

  .loader-container {
    justify-content: flex-end !important;
  }
}

.transformer-result-loader-message {
  margin-left: $s;
  font-size: $font-size-s;
  font-weight: $font-bold;
}

.transformer-result-loader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $m;
  margin-bottom: $m;
}

.button-retry {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: $m;
}

.transformer-result-error-card {
  .error-dialogue-container {
    margin-top: $xs;
    margin-bottom: $m;
  }
}

.freq-voltage-data {
  width: max-content;
  color: $color-text-black-secondary;
  font-size: $font-size-s;
}
.block-navigation-modal {
  .dialog.default {
    border-radius: $s !important;
    padding: $m $ml;
  }
  .header {
    padding: 0 0 $m !important;
  }
  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    p {
      margin: 0;
    }
  }
  .footer {
    justify-content: flex-end;
    padding: $ml 0 0 !important;
    div {
      display: flex;
      gap: $s;
    }
  }
}

.transformer-reselect-button {
  display: flex;
  justify-content: center;
  z-index: 10;
  margin-top: $s;
  height: $xxl;
  width: 100%;

  commonux-button > .light.primary-black {
    height: $xxl;
    width: 113px !important;
  }
}

.reselection-confirmation-modal-description {
  margin-left: $ml;
}

.reselction-confirmation-title-container {
  display: flex;
  margin-bottom: $m;
  align-content: center;
  align-items: center;
}

.reselection-confirmation-modal-button-container {
  margin-top: $m;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: $m;
}

.ambient-condition-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.country-varient-container {
  width: 25%;
  margin-right: $xs;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $font-size-xs;
  color: $color-black-primary;
  font-weight: $font-medium;
  cursor: not-allowed;
}

.ambient-altitude-container {
  width: 25%;
  margin-right: 2px;
  background-color: $color-grey-10;
  height: $ml;
  border-radius: $xs;
}
.ambient-elements-container {
  display: flex;
  flex-direction: row;
  font-size: $font-size-xs;
  font-weight: $font-regular;
  line-height: $line-height-s;
  color: $color-grey-50;
  justify-content: center;
  margin-top: $xs;
  padding-left: 2px;
  padding-right: 2px;
}

.ambient-motorTemparature-container {
  width: 25%;
  margin-right: 2px;
  height: $ml;
  background-color: $color-grey-10;
  border-radius: $xs;
}
.ambient-driveTemparature-container {
  width: 25%;
  margin-right: $xs;
  height: $ml;
  background-color: $color-grey-10;
  border-radius: $xs;
}
.ambient-edit-container {
  border-radius: $xs;
}

.ambient-condition-edit-modal-button-container {
  margin-top: $s;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.drive-temparature-input {
  width: 100%;
}

.motor-temparature-input {
  width: 100%;
}

.altitude-input {
  width: 100%;
  commonux-input > div > .optionalText {
    color: $color-status-error;
    font-size: $font-size-s;
  }
}
.apply-button-container {
  margin-left: $xs;
}

.ambient-condition-edit-modal {
  border-radius: $s;
}
commonux-input > div > .optionalText {
  font-size: $font-size-s;
}

.ambient-condition-values-label {
  margin-left: 2px;
}
.destination-country-value-container {
  margin-right: 2px;
}

.sidebar-button {
  margin-bottom: $s;
}

.ambient-button {
  background: $color-white-primary;
  border-radius: $s;
  padding: $s;
  margin-top: $xs;
  margin-bottom: $s;
  box-shadow: 0px 1px 0px $color-text-white-secondary;
}
.report-modal-sidenav {
  width: 0;
  max-width: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s linear;
}
.report-modal-sidenav-isopen {
  background-color: $color-brand-white;
  height: max-content;
  padding: $m;
  width: 100%;
  max-width: 325px;
  overflow: auto;
  border-radius: $s;
  opacity: 1;
  transition: all 0.3s linear;
}
.report-modal-sidenav {
  ol {
    counter-reset: item;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 60vh;
    overflow: scroll;
  }
  ol ol {
    counter-reset: subitem;
    padding-left: $sm;
  }

  li {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: $color-text-black-primary;
    text-overflow: ellipsis;
    font-size: $font-size-s;
    padding: $xs;
    font-weight: $font-regular;
    line-height: $line-height-m; /* 142.857% */
    cursor: pointer;
  }

  ol > li {
    counter-increment: item;
    // margin-bottom: 5px;
  }
  ol > li::before {
    content: counters(item, '.') '. ';
  }

  ol ol > li {
    counter-increment: subitem;
  }
  ol ol > li::before {
    content: counters(item, '.') '.' counters(subitem, '.') ' ';
  }
}

.product-dimensioning-details {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 16px);
}

.report-modal-table-of-content-heading {
  display: flex;
  align-items: center;
  gap: $s;
  padding: $sm 0;
  margin: 0;
  color: $color-text-black-secondary;
  font-size: $m;
  font-weight: $font-medium;
  line-height: $line-height-m;

  li {
    cursor: pointer;
  }
}

.table-of-contents-container {
  margin: $m 0 0;
  display: none;
  h2 {
    color: $color-text-black-primary;

    font-size: $font-size-s;
    font-weight: 700;
    line-height: $line-height-m; /* 142.857% */
    // border-bottom: 2px solid $color-text-black-primary;
    padding-bottom: 2px;
  }
}
.table-of-contents {
  padding: 0 0 0 50px;
  .content-heading {
    color: $color-text-black-primary;

    font-size: $font-size-xxs;

    font-weight: $font-light;
    line-height: $line-height-xxs;
    padding: 2px $ml 2px $s;
    cursor: pointer;
  }
}

.table-of-contents .font-bold {
  font-weight: 700;
}

.table-of-contents {
  .application-name {
    padding: 2px $ml;
  }
  .application-sub-names {
    padding: 2px $ml 2px $xxl;
  }
}

.report-efficiency-container {
  @extend .energy-losses-table;
  .energy-losses-table thead th {
    font-size: 9px;
    padding: 4px;
    text-align: right;
  }
  .energy-losses-table thead th:nth-child(1) {
    text-align: left !important;
  }
  td {
    padding: 6px $s;
    text-align: right !important;
    font-size: 9px !important;
  }
  td:nth-child(1) {
    text-align: center !important;
  }

  tr {
    background-color: #fff !important;
  }
  tbody {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 0;
    text-align: center;
  }
  .color-table-border tr:nth-child(-n + 8) td:first-child {
    border-left: none;
  }
  .efficiency-page-headers {
    color: #000;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    padding-bottom: $s;
    border-bottom: 4px solid #000;
  }
  th {
    color: #000 !important;
    text-transform: lowercase !important;
  }
  th:nth-child(6),
  th:nth-child(7) {
    width: 10%;
  }
  .header-row {
    white-space: unset;
  }

  .operating-points-table {
    margin: 0;
  }
  .chart-top-section {
    display: block;
    float: right;
  }
  .efficiency-page-table-msg {
    font-size: 9px;
  }
  .report-page-loss-container {
    .report-page-loss-subheader {
      font-size: 12px !important;
    }
  }
  .report-page-loss-content {
    font-size: 9px !important;
    ol {
      li {
        font-size: 9px;
      }
    }
  }
}
.report-efficiency-container td {
  border-left: 0;
}
.report-efficiency-container tr:nth-child(odd) {
  background-color: #fff;
}
.altitude-input-container {
  margin-bottom: $m;
}
.motor-temparature-input-container {
  margin-bottom: $m;
}
.drive-temparature-input-container {
  margin-bottom: $m;
}
.report-efficiency-container {
  .ProductInfoContainer {
    .Header {
      height: 130px;
      display: block;
      text-align: center;
      .HeaderIcon {
        margin: 0 !important;
      }
    }
    .HeaderTitle {
      font-size: 10px;
      font-weight: 700;
    }
    .HeaderSubtext1 {
      font-size: 10px;
      font-weight: 300;
    }
    .HeaderSubtext2 {
      font-weight: 300;
      font-size: $s;
    }

    .KeyValKey {
      font-size: 9px !important;
    }
    .KeyValValue {
      font-size: 9px !important;
    }
    .hr {
      margin: 0;
    }
    .pds-progressbar {
      margin-bottom: 0;
      padding: $s 0;
    }
    .progressbar-header {
      font-size: 9px;
      padding-bottom: 6px;
    }
    .progressbar-footer {
      font-size: 9px;
    }
    .Description {
      font-size: 9px;
      color: #696969;
      strong {
        font-weight: 500;
      }
    }
  }
}

.report-efficiency-container .PDSRangeChart-Title,
.report-efficiency-container .PDSRangeChart-Footer-Text,
.report-efficiency-container .PDSRangeChart-LegendText,
.report-efficiency-container .chart-legends,
.report-efficiency-container .motor-losses-motor-type-designation {
  font-size: 9px;
}
.report-efficiency-container .half-flex {
  font-size: 10px;
}
.report-efficiency-container .hr {
  margin: 0;
}

.report-efficiency-container .progressbar-header {
  font-size: 9px;
  margin-bottom: 0;
}
.report-efficiency-container .bigText {
  font-size: 9px !important;
}
.report-efficiency-container .progressbar-footer .bigText {
  color: #696969;
}
.report-efficiency-container .efficiency-info-section {
  margin: 16px 0;
}

.report-efficiency-container .efficiency-page-headers {
  margin: 32px 0 $s;
}

.report-efficiency-container
  .loss-determination-section
  .report-page-loss-subheader
  strong {
  font-size: 12px;
  line-height: 16px;
}
.report-efficiency-container .Description {
  font-size: 9px;
}

.report-efficiency-container .RangeChart-Title {
  font-size: 9px;
  margin-bottom: $s;
}
.report-efficiency-container .rangechart-efficiency-report {
  padding: 16px 0;
}
.report-efficiency-container .RangeChart-Footer-Text {
  padding-top: 40px;
  text-align: right;
  font-size: 9px;
}
.report-efficiency-container .RangeChart-Bar-Label {
  font-size: 10px;
}
.report-efficiency-container
  .rangechart-efficiency-report
  .RangeChart-VLine.VLine-Dash {
  border-left-style: dashed;
  border-left-width: 1px;
  align-items: flex-end;
  height: 40px;
}
.rangechart-efficiency-report {
  position: relative;
  .RangeChart-Bar {
    display: flex;
    width: 100%;
    &-Item:nth-child(1),
    &-Label:nth-child(1) {
      width: 33%;
    }
    &-Item:nth-child(2),
    &-Label:nth-child(2) {
      width: 66%;
    }
    &-Item:nth-child(3),
    &-Label:nth-child(3) {
      width: 100%;
    }
    &-Label {
      margin-top: $s;
    }
  }
  .RangeChart-VLines {
    top: -30px;
  }
  .RangeChart-VLine {
    height: $line-height-m;
    border-left: 3px solid;
    position: absolute;
    top: 45px;
    transform: translateX(1px);
    display: flex;
    flex-direction: row;

    .VLine-Text {
      position: absolute;
      display: flex;
      flex-direction: column;
      font-size: $font-size-xxs;
      line-height: $line-height-xxs;
    }

    &.VLine {
      &-Dash {
        border-left-style: dashed;
        border-left-width: 1px;
        align-items: flex-end;
        height: $xxl;

        .VLine-Text-Container {
          position: absolute;
          display: flex;
          justify-content: center;
        }

        .VLine-Text {
          top: 4px;
          flex-direction: column-reverse;
          align-items: center;
          color: #696969;
          width: max-content;
          font-size: 12px;
          .VLine-Extra-Text {
            font-size: 80%;
            color: #696969;
            white-space: nowrap;
          }
        }
      }

      &-Solid {
        align-items: flex-start;
        top: $element-medium-height;
        height: $element-small-height;
        transition: right 0.5s;

        .VLine-Text-Container {
          position: absolute;
          display: flex;
        }

        .VLine-Text {
          bottom: 0;
          font-weight: $font-medium;

          .VLine-Extra-Text {
            font-size: 90%;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .RangeChart-Footer {
    margin-top: 24px;
  }
}

.common-disclimer {
  .segment-subheader {
    color: $color-text-black-primary;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: $font-bold-700;
    line-height: $line-height-s;
    /* 150% */
    margin-top: $sm;
  }
  .segment-type-content {
    color: $color-text-black-primary;
    font-size: 9px;
    font-weight: $font-light;
    line-height: $line-height-m;
  }
}
.report-modal-header-left {
  gap: $s;
}

.motor-name-container {
  color: $color-text-black-secondary;
  font-size: $font-size-s;
  min-width: max-content;
}
.drive-name-container {
  color: $color-text-black-secondary;
  font-size: $font-size-s;
  min-width: max-content;
}

.tooltip-container {
  commonux-tooltip > .tooltip-container.left {
    top: -28px;
  }
}

.report-project--description--container {
  margin: 6px 0;
}
.report-project--description--container p {
  margin: 0;
}
.report-project--description--container .report-project--description--heading {
  color: $color-text-black-primary;
  font-size: $font-size-xs;
  font-weight: $font-bold-700;
  line-height: $line-height-s; /* 125% */
}

.report-project--description--container .report-project--description--text {
  color: $color-text-black-secondary;

  font-size: $font-size-xxs;
  font-weight: $font-regular;
  line-height: normal;
}

.load-profile-container {
  display: -webkit-flex;
  display: flex;
}
.load-profile-container .load-profile-segment {
  width: 50%;
}
.load-profile-segment div {
  width: 100%;
}
.load-profile-container .dimension-contents-container {
  width: 50%;
}

.load-profile-container .segment-type-sub-container {
  width: 100% !important;
  padding-right: 4px !important;
}
.font-text-transform-style {
  text-transform: capitalize;
}
.selection-vsd-container {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.vsd-container {
  margin-right: $s !important;
}
.selection-vsd-container .select-container {
  margin-left: $s !important;
}
.vsd-container,
.selection-container {
  width: 100%;
  padding: 0;
}
.selection-vsd-container .project-data-content-container {
  width: 100% !important;
}

.inverter--load--calc--data--container {
  display: -webkit-flex;
  display: flex;
  flex-direction: column-reverse;
}
.dimension-contents-container,
.calc-data--container {
  width: 100%;
}
.drive-container-ttile {
  border-top: 2px solid $color-grey-90;
  border-bottom: 1px solid $color-grey-90;
  color: $color-black-primary;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 12px !important;
  padding: $s;
}
.dimension-contents-container
  .segment-catalog-content.drive-container-header
  div:first-child {
  border-right: 1px solid $color-grey-20;
  font-weight: 700;
}
.drive-container-header div:nth-child(2) {
  border-left: none !important;
}
.drive-container-header div .border-left {
  border-left: 1px solid $color-grey-20 !important;
  padding: 0 !important;
}
.motor--catalouge-table-flex {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.motor--catalouge-table-flex .electrical-table-data {
  width: 100%;
}
.motor--catalouge-table-flex .mechincal-table-data {
  width: 100%;
}
.motor--catalouge-table-flex .mechincal-table-data .segment-title {
  display: none;
}

.dimension-contents-container .drive-container-header .drive-container-ttile {
  margin-top: $s;
}
.drive-catalouge--container .electrical-table-data {
  width: 100%;
}
.drive-catalouge--container .mechincal-table-data {
  width: 0;
}

.report-page-loss-subheader {
  color: $color-text-black-primary;
  font-size: $font-size-s;
  font-style: normal;
  font-weight: $font-bold-700;
  line-height: $line-height-s;
}
.drive-losses-progress-bar {
  padding: $s 0;
}
.inverter--load--calc--data--container .inverter-load-table {
  width: 100%;
}
.inverter--load--calc--data--container
  .inverter-load-table
  .drive-container-header {
  width: 100%;
}
.inverter-load-table .drive-container-ttile {
  width: 100% !important;
}
.inverter--load--calc--data--container
  .inverter-load-table
  .segment-content
  div {
  width: 100% !important;
}

.selection-sizing-tab-container {
  padding: 0 $s;
  display: flex;
  gap: $s;
  align-items: center;
  background-image: url('../../../../public/selection-dimension-tab.svg');

  background-repeat: no-repeat;
  background-position: $s;
  div {
    padding-left: $ml;
    font-weight: $font-medium;
    font-size: $font-size-m !important;
    line-height: $line-height-m;
    color: $color-text-black-secondary;
  }
  &:hover div {
    background-color: inherit;
    color: $color-text-black-primary;
    cursor: pointer;
  }
}
.selection-sizing-tab-container.selected {
  padding: $s;
  border-bottom: $hightlighted-border-color;
  color: $color-text-black-primary;
  div {
    color: $color-text-black-primary;
  }
}

.config-drive-tab-container {
  @extend .selection-sizing-tab-container;

  background-image: url('../../../../public/driveconfig.svg');
  button {
    height: $m;
    background-color: inherit !important;
  }
}
.config-drive-tab-container.selected {
  padding: 8px;

  border-bottom: $hightlighted-border-color;
  color: $color-text-black-primary;

  div {
    color: $color-text-black-primary;
  }
  button {
    height: $m;
    background-color: inherit !important;
  }
  svg {
    width: $m;
    height: $m;
  }
}
.efficiency-tab-container {
  @extend .selection-sizing-tab-container;
  background-image: url('../../../../public/efficiency-tab.svg');
  button {
    height: $m;
    background-color: inherit !important;
  }
}
.efficiency-tab-container.selected {
  padding: $s;
  border-bottom: $hightlighted-border-color;
  color: $color-text-black-primary;
  div {
    color: $color-text-black-primary;
  }
  button {
    height: $m;
    background-color: inherit !important;
  }
  svg {
    width: $m;
    height: $m;
  }
}

.tab-group-container {
  display: flex;
  gap: $s;
  align-items: center;
}

.plus-icon-container {
  cursor: pointer;
}

.pluscode-disable {
  color: $text-disabled-color;
  cursor: default;
}

.application-device .device-drive > .tooltip-container {
  width: 185px; //  to align with desin need to override the width to 100%

  text-wrap: wrap;
  .tooltip {
    padding: $s !important;
  }
  div {
    font-size: $sm;
    font-weight: $font-regular;
    line-height: $m; /* 13.8px */
  }
}

.application-device .device-drive {
  position: absolute;
  top: 0;
}
.application-device .device-drive > div {
  width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  right: -$m;
}
