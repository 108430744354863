@import '../../../../styles/main.scss';

.RangeChart {
  &-Title {
    font-size: $m;
  }

  &-Container {
    position: relative;
    padding: $xxl 0;
  }

  &-Bar {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 3fr);

    &-Item {
      border: $s solid;
    }

    &-Label {
      text-align: center;
      margin-top: 5px;
      position: relative;
      width: 100%;
      font-weight: bold;
    }

    :nth-child(1).RangeChart-Bar-Item {
      border-color: #f03040;
      background-color: #f03040;
    }

    :nth-child(2).RangeChart-Bar-Item {
      border-color: #ffd800;
      background-color: #ffd800;
    }

    :nth-child(3).RangeChart-Bar-Item {
      border-color: #0ca919;
      background-color: #0ca919;
    }
  }

  &-VLines {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    .RangeChart-VLine {
      height: $line-height-m;
      border-left: 3px solid;
      position: absolute;
      top: 45px;
      transform: translateX(1px);
      display: flex;
      flex-direction: row;

      .VLine-Text {
        position: absolute;
        display: flex;
        flex-direction: column;
      }

      &.VLine {
        &-Dash {
          border-left-style: dashed;
          border-left-width: 1px;
          align-items: flex-end;
          height: $xxl;

          .VLine-Text-Container {
            position: absolute;
            display: flex;
            justify-content: center;
          }

          .VLine-Text {
            top: 0;
            flex-direction: column-reverse;
            align-items: center;
            color: #696969;
            width: max-content;

            .VLine-Extra-Text {
              font-size: 80%;
              color: #696969;
              white-space: nowrap;
            }
          }
        }

        &-Solid {
          align-items: flex-start;
          top: $element-medium-height;
          height: $element-small-height;
          transition: right 0.5s;

          .VLine-Text-Container {
            position: absolute;
            display: flex;
          }

          .VLine-Text {
            bottom: 0;
            font-weight: $font-medium;

            .VLine-Extra-Text {
              font-size: 90%;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
