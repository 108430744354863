@import 'variables';

//this will eliminate use of @media for writing responsive CSS
@mixin responsive-media-query($sizes...) {
  @each $size in $sizes {
    //for extra-small screen
    @if ($size ==$media-xs) {
      @media only screen and (max-width: 576px) {
        @content;
      }
    }

    //for small screen
    @if ($size ==$media-sm) {
      @media only screen and (min-width: 576px) and (max-width: 768px) {
        @content;
      }
    }

    //for medium screen
    @if ($size ==$media-md) {
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        @content;
      }
    }

    //for large screen
    @if ($size ==$media-lg) {
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        @content;
      }
    }

    //for extra-large screen
    @if ($size ==$media-xl) {
      @media only screen and (min-width: 1200px) {
        @content;
      }
    }
  }
}

@mixin flex-align-center-vertical() {
  display: flex;
  align-items: center;
  @content;
}

@mixin grid-layout-with-breakpoints() {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;

  @include media-tablet() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-mobile() {
    grid-template-columns: 1fr;
  }
  @content;
}

@mixin margin-less-header() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    @content;
  }
}

@mixin text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @content;
}

@mixin multiline-text-ellipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  @content;
}

@mixin skeleton-Loading() {
  width: auto;
  border-radius: $xs;
  animation: skeleton-loading 1s linear infinite alternate;
  color: transparent !important;
  user-select: none;

  &.skeleton-text-limit {
    line-height: $font-size-xxs !important;
    margin-top: $xs;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: $color-grey-20;
    }

    100% {
      background-color: $color-grey-10;
    }
  }

  @content;
}

@mixin icon-color($color) {
  .icon-inner {
    fill: $color;
  }
}
