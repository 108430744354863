$font-family: ABBVoice;

$z-index-dialog-backdrop: 999;

// Common Viewpoint min and max widths
$mobileMaxWidth: 767.98px;
$tabletMinWidth: 768px;
$tabletMaxWidth: 1199.975px;
$desktopMinWidth: 1200px;
$desktopMaxWidth: 1439.99px;
$xlMinWidth: 1440px;
$xxlMinWidth: 1920px;

// basic sizes
$xs: 4px;
$s: 8px;
$sm: 12px;
$sm-14: 14px;
$m: 16px;
$ml: 24px;
$l: 32px;
$xl: 40px;
$xxl: 48px;
$xxxl: 64px;

// font sizes
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-ml: 20px;
$font-size-l: 24px;
$font-size-xl: 32px;
$font-size-xxl: 40px;
$font-size-xxxl: 48px;

// font weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;
$font-bold-700: 700;

// line heights
$line-height-xxs: 12px;
$line-height-xs: 14px;
$line-height-s: 16px;
$line-height-m: 20px;
$line-height-xl: 24px;
$line-height-xxl: 36px;
$line-height-xxxl: 40px;

$small-heading-text: 16px;
$element-small-height: 32px;
$element-medium-height: 40px;
$element-large-height: 48px;

$border-width-s: 1px;
$border-width-m: 2px;
$border-width-l: 4px;

$border-radius-xxs: 2px;
$border-radius-xs: 4px;
$border-radius-s: 8px;
$border-radius-m: 12px;
$border-radius-l: 16px;
$border-radius-xl: 20px;
$border-radius-xxl: 24px;

// colors
//brand colors
$color-brand-black: #000000;
$color-brand-red: #ff000f;
$color-brand-white: #ffffff;

$color-red-100: #3e0000;
$color-red-90: #5c0006;
$color-red-80: #a30005;
$color-red-70: #cc0815;
$color-red-60: #e12e2e;
$color-red-50: #ff4754;
$color-red-40: #ff757e;
$color-red-30: #ff99a0;
$color-red-20: #ffc9cd;
$color-red-10: #ffe8e9;

$color-grey-100: #0f0f0f;
$color-grey-90: #1f1f1f;
$color-grey-80: #333333;
$color-grey-70: #525252;
$color-grey-60: #686868;
$color-grey-50: #848484;
$color-grey-40: #9f9f9f;
$color-grey-30: #bababa;
$color-grey-20: #dbdbdb;
$color-grey-10: #ebebeb;

$color-blue-100: #080261;
$color-blue-90: #150c9b;
$color-blue-80: #2c20d4;
$color-blue-70: #2a35ff;
$color-blue-60: #3366ff;
$color-blue-50: #4c85ff;
$color-blue-40: #77a3fc;
$color-blue-30: #9ebef7;
$color-blue-20: #c5dff9;
$color-blue-10: #e0f0ff;

//blue-ui
$color-ui-blue-10: #e0f0ff;
$color-ui-blue-20: #c5e0fa;
$color-ui-blue-30: #9ebff7;
$color-ui-blue-40: #77a3fc;
$color-ui-blue-50: #4d85ff;
$color-ui-blue-60: #3366ff;
$color-ui-blue-70: #2a35ff;
$color-ui-blue-80: #2c20d4;
$color-ui-blue-90: #160c9c;
$color-ui-blue-100: #080261;

$color-green-100: #0ca919;

//status colors
$color-status-error: #ef3934;
$color-status-error-background: #fbf0f0;
$color-status-warning: #ffa200;
$color-status-warning-background: #fef9ef;
$color-status-info: #0052ff;
$color-status-info-background: #d8e4ff;
$color-status-success: #21a67a;
$color-status-success-background: #eef8f5;
$color-status-unknown: #bababa;
$color-status-unknown-background: #f5f5f5;

$color-white-primary: #ffffff;
$color-white-secondary: #fafafa;
$color-white-tertiary: #f5f5f5;
$color-black-primary: #000000;
$color-black-secondary: #050505;
$color-black-tertiary: #0a0a0a;

// data-viz colors
$color-green-100: #011900;
$color-green-90: #10290e;
$color-green-80: #1a4717;
$color-green-70: #1e6119;
$color-green-60: #477a43;
$color-green-50: #51a34b;
$color-green-40: #75ba70;
$color-green-30: #98d694;
$color-green-20: #bfedbb;
$color-green-10: #e2f7df;

//Opacity colors
$color-black-opacity-base: #000000;
$color-black-opacity-90: RGB($color-black-opacity-base, 0.88);
$color-black-opacity-80: RGB($color-black-opacity-base, 0.8);
$color-black-opacity-70: RGB($color-black-opacity-base, 0.72);
$color-black-opacity-60: RGB($color-black-opacity-base, 0.64);
$color-black-opacity-50: RGB($color-black-opacity-base, 0.56);
$color-black-opacity-40: RGB($color-black-opacity-base, 0.48);
$color-black-opacity-30: RGB($color-black-opacity-base, 0.4);
$color-black-opacity-20: RGB($color-black-opacity-base, 0.32);
$color-black-opacity-10: RGB($color-black-opacity-base, 0.24);
$color-black-opacity-8: RGB($color-black-opacity-base, 0.16);
$color-black-opacity-6: RGB($color-black-opacity-base, 0.12);
$color-black-opacity-4: RGB($color-black-opacity-base, 0.08);
$color-black-opacity-2: RGB($color-black-opacity-base, 0.04);

//text colors
$color-text-white-primary: #ffffff;
$color-text-white-secondary: #dbdbdb;
$color-text-white-disabled: RGB($color-text-white-primary, 0.4);

$color-text-black-primary: #1f1f1f;
$color-text-black-secondary: #696969;
$color-text-black-disabled: RGB($color-text-black-primary, 0.4);

// second-level tokens
$primary-text-on-light: $color-grey-90;
$primary-text-on-dark: $color-grey-20;
$secondary-text-on-light: $color-grey-60;
$secondary-text-on-dark: $color-grey-50;
$tertiary-text-on-light: $color-grey-30;
$tertiary-text-on-dark: $color-grey-70;

$half-opacity-backdrop-on-light: RGB($color-grey-80, 0.5);
$half-opacity-backdrop-on-dark: RGB($color-grey-100, 0.5);
$full-opacity-backdrop-on-light: RGB($color-grey-80, 1);
$full-opacity-backdrop-on-dark: RGB($color-grey-100, 1);

$color-content-background-light: $color-white-primary;
$color-content-background-light-snow: $color-white-primary;
$color-content-background-light-cloud: #f5f5f5;
$color-content-background-dark: $color-grey-80;
$color-content-background-dark-graphite: #333333;
$color-content-background-dark-charcoal: $color-grey-90;

$border-saperator-color: #ebebeb;
$text-disabled-color: rgba(31, 31, 31, 0.4);

//Border for input controls
$control-border-color: $color-grey-20;
$hightlighted-border-color: 2px solid $color-black-opacity-base;
$control-border-lg: 2px solid $control-border-color;
$control-border-md: 1px solid $control-border-color;
$control-border-radius: 4px;

$control-border-separator: 1px solid RGB(210, 210, 210, 0.8);

//media breakpoints
$media-xs: 'extra-small';
$media-sm: 'small';
$media-md: 'medium';
$media-lg: 'large';
$media-xl: 'extra-large';

$color-footer-border: $color-grey-20;
$color-background-black-tertiary: #0a0a0a;
$color-foreground-disable: rgba(31, 31, 31, 0.5);
$background-alternative: #f5f5f5;
