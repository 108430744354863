@import 'variables';
@import 'utils';

.narrow-menu {
  position: absolute;
  display: inline-block;
  height: auto;
  cursor: pointer;
  line-height: initial;
  font-size: initial;

  .active {
    & > button {
      &.discreet {
        background-color: $color-black-opacity-6 !important;
      }
    }
  }

  .menu-container {
    position: relative;
    display: none;
    z-index: 999;

    .content {
      position: absolute;
      overflow: hidden;
      list-style: none;
      padding: $s 0;
      background-color: $color-white-primary;
      min-width: 163px;
      border-radius: $border-radius-xs;
      margin: 0;
      box-shadow: 0px $xs $sm rgba(0, 0, 0, 0.25);
      top: $ml;
      left: -$ml;
      .menu-item {
        box-sizing: border-box;
        padding-left: $m;
        padding-right: $sm;
        width: 230px;

        @include flex-align-center-vertical();

        &.large {
          height: $xl;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        &.medium {
          height: $l;
          padding-top: 6px;
          padding-bottom: 6px;
        }

        &:hover {
          background-color: $color-grey-10;
          width: 230px;
        }
      }
      .menu-seperator {
        @include flex-align-center-vertical();
        padding: $s 0;
        flex-direction: column;
        align-items: flex-start;
        gap: $sm;
        align-self: stretch;
        .menu-seprator-line {
          height: 1px;
          background-color: $color-grey-30;
          width: 100%;
        }
      }

      .menu-disabled {
        color: $color-foreground-disable;
        cursor: default;
        padding-top: $s;
        padding-bottom: $s;

        &:hover {
          background-color: unset;
          width: 230px;
        }
      }
    }
  }

  .show {
    display: block;
  }
}
.label-non-clickable-text {
  @include flex-align-center-vertical();
  padding: $s;
  gap: $sm;
  align-self: 'stretch';
  margin: 0;
  color: $color-text-black-secondary;
  cursor: context-menu;
}
