@import '../../styles/main.scss';
@import '../../styles/_utils.scss';
@import '../../styles/_variables.scss';

.privacy-banner-container {
  width: 684px;
  .dialog {
    max-width: 684px !important;
    @include media-mobile() {
      max-width: 100% !important ;
    }
  }
  .privacy-banner-body {
    width: 100%;
    display: flex;
    gap: $ml;
    flex-direction: column;
    .privacy-banner-text {
      font-size: $font-size-s;
      color: $color-grey-90;
      line-height: $line-height-s;
      margin: 0;
      a {
        color: $color-grey-90;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    .privacy-banner-analytic {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: $xs;

      label {
        font-weight: $font-regular;
        font-size: $font-size-s;
        color: $color-text-black-primary;
        padding: 0 $s;
      }

      .content {
        max-width: 100%;
        padding: $xs $sm;
        p {
          margin: 0 auto;
          font-weight: $font-regular;
          font-size: $font-size-xs;
          color: $color-text-black-secondary;
          line-height: $line-height-s;
        }
      }
    }
    .privacy-banner-preferences {
      @extend .privacy-banner-analytic;
    }
    .sepratorLine {
      width: 100%;
      border: $border-width-s solid $color-grey-30;
    }
  }
  .privacy-banner-button-container {
    display: flex;
    padding: $ml $ml $m;
    justify-content: flex-end;
    align-items: center;
    gap: $m;
    align-self: stretch;
    flex: 1 0;
    @include media-mobile() {
      flex-direction: column-reverse;
      width: 100%;
      padding: $ml 0 $m;
    }
    commonux-button {
      @include media-mobile() {
        width: 100%;
        button {
          border: none;
        }
      }
    }
    section {
      @extend .privacy-banner-button-container;
      padding: 0;
    }
  }
 .privacy-banner-china-concent {
   @extend .privacy-banner-analytic;
   display: flex;
   flex-direction: row;

   commonux-checkbox>div {
     display: flex;
     flex-direction: row;
   }

   commonux-checkbox>div>.content.medium {
     margin-left: 0;
   }
 }
}
