@import './main.scss';

.D3 {
  text-align: center;
  padding-left: $l;
}

.bar {
  fill: steelblue;
  opacity: 0.5;
}

.axis--x path {
  display: none;
}

.grid .tick {
  stroke: rgb(240, 238, 238);
  opacity: 0.2;
}

.darkLine .tick line {
  stroke-width: 1;
  opacity: 1;
}

.grid path {
  stroke-width: 0;
}

.hideLine path {
  visibility: hidden;
}

.darkLine {
  stroke-width: 0;
}

.vertical-grid {
  stroke: #ccc;
}

.ordinate-text {
  font-size: $font-size-xs;
  transform: translateY(12px);
}

.right-align {
  position: absolute;
  right: 100px;
}

.bottom-arrow:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  background: $color-brand-white;
  /* Grey/30 */
  border-top: $font-size-xxs solid #181616;
  border-left: $font-size-xxs solid transparent;
  border-right: $font-size-xxs solid transparent;
  margin-top: $xs;
}

.D3-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .D3-logo {
    animation: D3-logo-spin infinite 20s linear;
  }
}

.D3-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.D3-link {
  color: #61dafb;
}

@keyframes D3-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.D3 svg {
  width: 95%;
  height: auto;
  margin: 20px 0;
}

.tick text{
  font-size: 12px !important;
}