@import 'variables';
@import '../styles/main.scss';

.button-container-item {
  position: sticky;
  bottom: 64px;
  padding-top: $ml;
  z-index: 10;
  text-align: -webkit-center;

  .button-group-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: $xxl;
    border-radius: 100px;
    z-index: 5;
    box-shadow: 0px $m $ml 0px rgba(0, 0, 0, 0.12),
      0px 2px $s 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);

    .button-group-item-sidebar-closed {
      left: 10%;
    }

    @include media-mobile() {
      width: 77%;
    }

    .left-button {
      border-radius: 100px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      width: 33.39%;
    }

    .middle-button {
      border-radius: 0;
      width: 27.81%;
    }

    span:after {
      content: '';
      position: absolute;
      top: 40%;
      width: 1px;
      height: 50%;
      background: $color-brand-white;
    }

    .right-button {
      border-radius: 100px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      cursor: pointer;
      width: 38.99%;
    }

    .button-group-item-common {
      color: $color-grey-20;
      line-height: $line-height-s;
      font-weight: $font-medium;
      font-size: $font-size-s;
      background-color: #000000e0;
      cursor: pointer;
    }

    .disabled {
      color: rgba(255, 255, 255, 0.4) !important;
      cursor: auto;
    }
  }
}

.button-group-item-sidebar-closed {
  margin-left: 0;
}

.button-group-item-sidebar-open {
  width: 35%;
}
