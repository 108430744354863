@import '../../../../styles/main.scss';

.sliderWithInput-container {
  padding-right: $s;
  width: 95%;
  margin-top: $m;
}

.user-defined-points {
  display: grid;
  grid-template-columns: 2% 45% 45% 5%;
  grid-gap: 2%;
  padding: $s $m;
  margin: $s 0;
  align-items: center;
  border-bottom: 1px solid $color-grey-20;
}

.user-defined-loadpoints-container {
  border-top: 1px solid $color-grey-20;
  margin-top: $ml;
  padding-top: $ml;
}

.operating-points-select {
  margin: $m 0;
}

.operating-points-heading {
  margin-bottom: $s;
}

.speed-torque {
  display: grid;
  grid-template-columns: 2% 45% 45%;
  grid-gap: 2%;
  color: $color-text-black-secondary;
  font-size: $font-size-s;
  font-weight: $font-medium;
  border-bottom: 1px solid $color-grey-90;
  margin-bottom: $m;
  padding: $m;
}

.slider-input .inputWrapper {
  width: $xxxl !important;
  height: $xl !important;
}

.slider-diabled {
  pointer-events: none;
  opacity: 0.5;
}

.half-flex {
  flex-basis: 48% !important;
  font-size: $font-size-s;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $line-height-m;
  white-space: pre-line;
}
.loss-determination-section {
  margin-top: $xl;
}

.efficiency-info-section {
  margin: $xl 0 0;
}

.margin-sections {
  margin-top: $xl !important;
}

.fs-16 {
  font-size: $font-size-m !important;
}
.efficiency-info-notification {
  font-size: $font-size-s;
  padding: $sm;
  border-radius: $xs;
  background: var(--status-colors-info-background, #d8e4ff);
  margin: 0 0 $xl 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.efficiency-info-notification img {
  width: $ml;
  margin-right: $m;
  color: blue;
}

.RangeChart-Footer {
  height: 32px;
  width: 100%;
  background: linear-gradient(to top left, #e0f0ff 50%, white 50%);
  text-align: left;
  margin-top: $m;
}

.RangeChart-Footer-Text {
  padding-top: $xl;
}

.half-flex-chart {
  flex-basis: 60%;
}

.half-flex-info {
  flex-basis: 38%;
  font-size: $font-size-s;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $line-height-m;
  white-space: pre-line;
}

.hr {
  margin: $xs 0;
  height: 1px;
  border-top: 1px solid #dbdbdb;
}
.operating-points-table {
  margin: 100px 0 $xl 0;
}

.report-page-loss-content,
.report-page-loss-content li {
  padding-bottom: 10px;
  color: var(--text-black-primary, #1f1f1f);
  font-size: $font-size-s;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $line-height-m;
  white-space: pre-line;
}

.mt-10 {
  margin-top: $m;
}
.mt-40 {
  margin-top: $xxl;
}
.mt-40 {
  margin-top: $xxl;
}

.pds-charts-container {
  @include media-desktop() {
    max-width: 1000px;
  }

  @include media-xl() {
    max-width: 1000px;
  }
}

.value-column {
  display: flex;
  border: $border-width-m solid $color-grey-20;
  justify-content: space-between;
  padding: $s;
  margin: $s;
  border-radius: $xs;

  .table-input {
    border: 0;
    outline: none;
    width: 100%;

    .table-input:focus {
      outline: none;
    }
  }

  .no-arrow::-webkit-outer-spin-button,
  .no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-arrow {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .unit-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    font-weight: $font-medium;
  }
}

.custom-input-with-suffix {
  .commonux-select-container .suffix-container .text-input {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

.efficiency-note {
  font-size: $font-size-s;
  display: flex;
  padding: $font-size-xxs $sm;
  align-items: center;
  border-radius: $s;
  background: rgba(186, 186, 186, 0.08);
  font-weight: $font-medium;
  line-height: 115%;
  margin-top: $m;
}

.efficency-info-icon {
  margin-right: $s;
}

.bold-imp-note {
  font-weight: $font-bold;
}
.efficiency-error-page {
  margin: auto;
  height: 50vh;
  text-align: center;
  width: 50%;
}

.eff-error-no-data {
  font-size: $font-size-m;
  font-weight: $font-medium;
  padding: $sm 0;
}

.eff-error-no-data-msg {
  font-size: $font-size-xs;
  font-weight: $font-medium;
  padding-bottom: $sm;
}
.set-table-overflow {
  overflow: auto;
}

.pds-progressbar {
  margin-bottom: $s;
}

.transformer-info-notification {
  @extend .efficiency-info-notification;
  margin: 0 0 $ml 0;
}

.dimension-info-notification {
  @extend .efficiency-info-notification;
  margin: 0 0 $ml 0;
  border-radius: $s;
}

.pds-delete {
  margin-bottom: $l;
}

.udp-add {
  margin-top: $sm;
}

.report-efficiency-note {
  font-size: 9px;
}
